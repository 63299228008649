section.projetos-index {

    padding: 60px 30px;
    overflow: hidden;

    @include desktop {
        padding: 120px;
    }

    .projetos-index-header {
        text-align: center;
        position: relative;
        z-index: 2;
    }

    .projetos-index-frase {
        font-size: 2em;
        color: $orange;
        @include desktop {
            width: 50%;
            margin: 0 auto;
        }
    }

    .projetos-index-filter {
        
        margin: 2em;
        display: none;

        @include tablet {
            display: block;
        }
        
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            
        }

        li {
            
            margin: 0;
            padding: 0;
            list-style: none;
            display: inline-block;

            a {
                margin: 0 20px;
                @include animated-underline($medium-gray);
            }

        }

        li + li::before {
            content: " • ";
            color: $main-red;
        }

    }

    .projetos-index-list {
        
        margin: 60px 0;
        position: relative;
        z-index: 2;

        @include desktop {
            margin: 120px 0;
        }

        .projetos-index-list-wrapper {
            transition: all 0.5s;
        }

        .projeto {

            a {
                
                display: block;
                position: relative;
                overflow: hidden;

                figure {
                    // position: absolute;
                    line-height: 0;
                }

                .projeto-info {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    // width: calc(100% - 2px);
                    // height: calc(100% - 2px);
                    text-align: center;
                    top: 0;
                    left: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #ffffff;
                    font-size: 1.2em;
                    opacity: 0;
                    background-color: rgba(0,0,0,0.3);
                    transition: all 0.6s;
                    //margin: 20px;
                    border: 16px solid white;
                    transform: scale(1.1);
                }

                &:hover {
                    .projeto-info {
                        opacity: 1;
                        transform: scale(1);
                    }
                }
            }
        }

    }

}