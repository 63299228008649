.parallax-svg {

    position: absolute;
    z-index: 0;

    top: 0;
    left: 0;
    
    &.svg-256 {
        width: 128px;
        margin-top: -64px;
        margin-left: -64px;
        @include desktop {
            width: 14vw;
            margin-top: -7vw;
            margin-left: -7vw;
        }
    }
    
    &.svg-512 {
        width: 256px;
        margin-top: -128px;
        margin-left: -128px;
        @include desktop {
            width: 28vw;
            margin-top: -14vw;
            margin-left: -14vw;
        }
    }

    &.svg-1024 {
        width: 512px;
        margin-top: -256px;
        margin-left: -256px;
        @include desktop {
            width: 56vw;
            margin-top: -28vw;
            margin-left: -28vw;
        }
    }

}