$path-img: "../assets/images/";
$path-fonts: "../assets/fonts/";

$pure-black: #000000;
$pure-white: #ffffff;

$main-blue: #003555;
$dark-blue: #121221;

$background-bege: #EBE9DB;
$main-red: #E03E4A;
$greenish: #BBBE8B;
$dark-gray: #676B66;
$orange: #E87851;
$medium-gray: #707070;
$medium-bege: #DCDDC3;
$light-gray: #eee;
$dark-bege: #CED0AA;

@function responsive($value1, $value2, $width1: 320, $width2: 1920) {
  $x1: $width1;
  $x2: $width2;
  $y1: $value1;
  $y2: $value2;
  $a: ($y2 - $y1) / ($x2 - $x1);
  $b: ($x2 * $y1 - $x1 * $y2) / ($x2 - $x1);
  @return calc(#{$a * 100vw} + #{$b * 1px});
}

@mixin animated-underline($color, $padding: 0.5em) {
  color: $color;
  padding-bottom: 0;
  border-bottom: 1px solid transparent;
  transition: all 0.5s;
  &:hover, &.active, a:hover &, a.active & {
    padding-bottom: $padding;
    border-bottom: 1px solid $color;
  }
}