.slider-blog {
	position: relative;
	padding-bottom: 150px;

	@media(min-width: 1000px) {
		padding-bottom: responsive(150, 300, 1000, 1920);
	}

	&:before {
		content: '';
		position: absolute;
		bottom: 0;
		height: calc(50% + 200px);
		width: 100%;
		background-color: $dark-gray;
		z-index: -2;
	}

	h2 {
		font-size: 40px;
		color: $dark-gray;
		margin-bottom: 50px;
		margin-left: 10vw;

		@media(min-width: 1000px) {
			font-size: 2.4em;
			margin-bottom: 100px;
		}
	}

	.swiper-container {

		@media(min-width: 1000px) {
			width: 80%;
			margin: 0 auto;
		}
		.swiper-wrapper {
			.swiper-slide {
				width: 60%;

				.img {
					height: 200px;
					background-size: cover;
					background-position: center;
					background-repeat: no-repeat;
					width: 100%;
					margin-bottom: 20px;

					@media(min-width: 1000px) {
						height: 400px;
						width: 300px;
						margin-bottom: 40px;
					}
				}

				p {
					color: white;
					font-size: 16px;
					line-height: 1;
					font-weight: 400;
					z-index: 2;

					@media(min-width: 1000px) {
						font-size: 1em;
					}
				}
			}
		}

		.swiper-button-prev,
		.swiper-button-next {
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
			width: 35px;
			height: 35px;
			outline: none;

			@media(min-width: 1000px) {
				width: 70px;
				height: 70px;
			}

			&:after {
				content: '';
			}
		}

		.swiper-button-prev {
			background-image: url($path-img + 'arrow-prev.svg');
			left: 5vw;

			@media(min-width: 1000px) {
				left: -5vw;
			}
		}

		.swiper-button-next {
			background-image: url($path-img + 'arrow-next.svg');
			right: 5vw;

			@media(min-width: 1000px) {
				right: -5vw;
			}
		}
	}

	.typeform {
		color: $orange;
		font-size: 40px;
		text-align: center;
		margin: 150px auto 0;
		display: block;

		@media(min-width: 1000px) {
			font-size: 2.7em;
			margin: 300px auto 0;
		}
	}
}