.slider-projects {
	
	position: relative;

	&:after {
		content: '';
		position: absolute;
		height: calc(50% + 13vw);
		width: 100%;
		bottom: 0;
		left: 0;
		background-color: $background-bege;
		z-index: -1;
	}

	.swiper-container {

		.swiper-wrapper {

			.swiper-slide {

				width: 60vw;
				height: 60vw;
				margin: 0 2vw;
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
				position: relative;
				cursor: pointer;

				@include tablet {
					height: 35vw;
				}

				a {

					width: 100%;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;

					&:before {
						content: '';
						height: 100%;
						width: 100%;
						top: 0;
						left: 0;
						background-color: $pure-black;
						opacity: .2;
						position: absolute;
					}
					
					.slider-projects-label {
						display: none;
						color: #fff;
						font-size: 1em;
						text-align: center;
						z-index: 2;
						@include desktop {
							font-size: 1.2em;
						}
					}
					
				}

				&.swiper-slide-active {
					.slider-projects-label {
						display: block;
					}
				}
			}
		}

		.swiper-button-prev,
		.swiper-button-next {
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
			width: 50px;
			height: 50px;
			margin-top: -25px;
			outline: none;
			transition: all 0.5s;

			&:hover {
				transform: scale(1.2);
			}

			@include tablet {
				width: 100px;
				height: 100px;
				margin-top: -50px;
			}

			&:after {
				content: '';
			}
		}

		.swiper-button-prev {
			background-image: url($path-img + 'arrow-prev.svg');
			left: 2vw;
			@include tablet {
				left: 5vw;
			}
		}

		.swiper-button-next {
			background-image: url($path-img + 'arrow-next.svg');
			right: 2vw;
			@include tablet {
				right: 5vw;
			}
		}
	}

	.see-all {

		margin: 0 auto;
		padding: 8vw 0 16vw;
		display: table;

		a {
			@include animated-underline($medium-gray);
		}
	}
}

.slider-projects-label {
	@include animated-underline(white, 0.3em);
}