@font-face {
  font-family: "Amasis";
  src:url($path-fonts + "Amasis-Light.woff2") format("woff2"),url($path-fonts + "Amasis-Light.woff") format("woff"),url($path-fonts + "Amasis-Light.otf") format("opentype");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Amasis";
  src:url($path-fonts + "Amasis-Regular.woff2") format("woff2"),url($path-fonts + "Amasis-Regular.woff") format("woff"),url($path-fonts + "Amasis-Regular.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
}
        