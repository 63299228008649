.footer {

	overflow: hidden;
	background-color: $dark-gray;
	padding: 40vw 10vw 10vw;
	min-height: 100vh;
	color: $pure-white;

	@include tablet {
		padding: 0 10vw 10vw;
	}

	@media(min-width: 700px) {
		align-items: center;
		display: flex;
		justify-content: space-between;
	}

	.marca {

		position: relative;
		z-index: 1;
		margin-bottom: 30px;
		width: 260px;
		display: none;

		@include tablet {
			display: block;
			width: 40%;
			margin-bottom: 0;
		}
	}

	.infos {

		margin: 0 auto;
		position: relative;
		z-index: 1;

		p {
			margin: 0 0 0.5em;
		}

		a {
			color: $pure-white;
			font-weight: 400;
			margin-bottom: 10px;
		}

		.social {
			margin-top: 2em;
			a {
				transition: all 0.5s;
				display: inline-block;
				&:hover {
					transform: scale(1.2);
				}
			}
		}

		svg {
			fill: $pure-white;
			height: 35px;

			&:first-of-type {
				margin-right: 10px;
			}
		}
	}
}