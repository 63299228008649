.page-title {

    padding: 30px 60px 0;

    @include desktop {
        margin: 60px 120px 0;
    }
    

    h1, h2 {
        color: #666;
    }

    h1 {
        font-size: 4em;
    }
}