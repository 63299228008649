section.sanfona {

	padding: 7vw;
	background: #e87852;
	color: #fff;
	overflow: hidden;

	@include desktop {
		padding: 14vw;
	}

	h3 {
		text-align: center;
		font-size: 2em;
		margin: 1em 0;
		position: relative;
		z-index: 2;
	}

	.sanfona-topicos-item {
		
		position: relative;
		z-index: 2;
		border: 1px solid #fff;
		&:not(:first-child) {
			border-top: 0;
		}

		.accordion-trigger {
			width: 100%;
			display: block;
			padding: 20px;
			color: #fff;
			font-weight: 400;

			.icon-more {

				margin-right: 10px;

				@include desktop {
					margin-right: 30px;
				}

			}

		}

		.accordion .accordion-wrapper {
			
			padding: 10px 30px 30px;
			font-size: 80%;

			@include desktop {
				padding: 10px 70px 30px;
			}

			p {
				margin-bottom: 1em;
			}

			ul {
				margin: 1em;
			}

			.galeria {
				margin-top: 2em;
			}
		}

	}

}

.accordion {
    overflow-y: hidden;
    max-height: 1000px;
    transition: all .5s;
    &.accordion-longtext {
        max-height: 2200px;
        transition: all 1s;
    }
}

.accordion.closed {
    max-height: 0;
}


.icon-more {
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;

    span {
        position: absolute;
        height: 2px;
        width: 100%;
        background: #fff;
        top: 50%;
        left: 0;
        transform: rotate(0deg);
        transition: .25s ease-in-out;
    }

    &.closed span:nth-child(1) {
        transform: rotate(90deg);
    }

    &.closed span:nth-child(2) {
        transform: rotate(180deg);
    }
}