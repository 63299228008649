.cover-parallax {
	//width: 100%;
	height: 100vh;
	
	.background	{

		height: 100vh;
	
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		position: relative;

		@include desktop {
			background-attachment: fixed;
		}

		&:after {
			content: '';
			position: absolute;
			background-color: $pure-black;
			opacity: .3;
			height: 100%;
			width: 100%;
			top: 0;
			left: 0;
		}

		.frase {
			color: $pure-white;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			text-align: center;
			font-size: 2em;
			width: 90%;
      z-index: 2;
      @include tablet {
        width: 70%;
      }
		}
	}
}
