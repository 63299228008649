body.search,
body.page-template-page-blog,
body.single-post {
    background-color: #f9f9f9;
}


.searchform {

    .search-wrapper {
        display: flex;
    }
    input[type="text"] {
        font-family: "Amasis";
        font-weight: 400;
        font-size: 1.2em;
        padding: 0.7em;
        border: none;
        color: #666;
        //width: calc(90% - 32px);
        flex-grow: 1;
    }
    input[type="submit"] {
        background: url(../assets/images/search.svg) no-repeat center center;
        background-size: cover;
        width: 50px;
        height: 50px;
        margin: 0;
        margin-left: -25px;
        padding: 0;
        border: none;
        cursor: pointer;
        &:hover {
            opacity: 0.8;
        }
    }
}

.search-mobile {

    padding: 30px 60px 0;
    position: relative;
    z-index: 1;

    @include desktop {
        display: none;
    }

    .searchform {
        max-width: 640px;
        margin: 0 auto;
    }
}

.blog-wrapper {

    padding: 30px 60px 0;

    @include widescreen {
        margin: 0 120px 0;
    }

    article {

        margin: 20px auto;
        background: #fff;
        padding: 20px;
        max-width: 640px;
        position: relative;
        z-index: 1;

        @include mobile {

            max-width: 360px;

            img {
                width: 100%;
                height: auto;
            }

        }

        @include desktop {
            margin: 20px 0;
        }


        h3 {
            font-size: 1.5em;
            margin: 0.2em 0;
        }

        h6 {
            margin-bottom: 2em;
        }

        p {
            margin: 1em 0;
            font-size: 0.8em;
        }
        
        a {
            color: $orange;
        }

        a.readmore {
            @include animated-underline($medium-gray);
        }

    }

}

.blog-sidebar {

    position: relative;
    z-index: 1;
    max-width: 360px;
    padding: 20px;
    display: none;
    
    @include desktop {
        display: block;
    }

    .widget {

        margin: 0 0 30px;
        font-size: 16px;

        h3 {
            margin-bottom: 20px;
            font-weight: 400;
            color: $orange;
        }

        &.widget_recent_entries {
            ul {
                list-style-position: inside;

                li {
                    margin-bottom: 10px;

                    a {
                        @include animated-underline($medium-gray,0.3em);
                    }

                }
                
            }
        }
    }
}

.single-post {

    .post-content {

        background: #fff;
        padding: 30px;
        margin-bottom: 60px;

        @include desktop {
            padding: 60px;
            margin-right: 30px;
        }

        p {
            margin: 0 0 1em;
            line-height: 1.4em;
        }

        img {

            margin: 50px 0;

            &.aligncenter {
                display: block;
                margin: 50px auto;
            }

        }

        

        &.post-footer {
            
            a {
                @include animated-underline($medium-gray);
            }

            .column {
                &.ac {
                    text-align: center;
                }
                &.al {
                    text-align: left;
                }
                &.ar {
                    text-align: right;
                }
            }

        }

    }

}

.graviola-pagination {

    background: $background-bege;
    position: relative;
    z-index: 1;

    .graviola-pagination-wrapper {
        margin: 0 auto;
        padding: 30px;
        text-align: center;
    }

    a {
        color: $orange;
        line-height: 3em;
    }

    body.single & {
        color: $orange;
        a {
            font-size: 90%;
            @include animated-underline($orange,0.3em);
        }
    }

    ul {
        
        margin: 0;
        padding: 0;
        list-style: none;
        display: inline-block;

        li {
            margin: 0;
            padding: 0;
            display: inline-block;

            a {
        
                margin: 4px;
                padding: 6px 12px;
                user-select: none;
                border-radius: 4px;
                
        
                &[disabled] {
                    opacity: 0.5;
                }
        
                &:hover:not(.is-current):not([disabled]) {
                    background: $dark-bege;
                }
        
                &.is-current {
                    background: $orange;
                    color: $background-bege;
                }
        
                
            }
        }
    }
}