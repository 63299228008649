.parallax-blocks {

	padding: 60px 0;
	overflow: hidden;

	color: #333;


	.single-col {
	
    padding: 0 2.4rem;
    @include tablet {
      padding: 0 20vw;
    }

		.poesia {
			position: relative;
			font-size: 1.2em;
			line-height: 1.2;
			margin: 10vw auto;
			max-width: 500px;
			z-index: 1;
			&.poesia-wide {
				max-width: 800px;
			}
		}

		.frase {
			margin: 10vw auto;
			position: relative;
			text-align: center;
			font-size: 2em;
			font-weight: 300; 
			z-index: 1;
			color: $orange;
		}

		
	}

	.double-col {

		position: relative;
		max-width: 1440px;
		margin: 0 auto;
		z-index: 1;

		&.double-col-padding {
			margin: 7vw;
		}
		
		.double-col-padding-text {

			padding: 0 7vw;

			@include tablet {
				padding: 7vw;
			}
		}

		.double-col-text {

			padding: 4vw 14vw;

			h3 {
				font-size: 2em;
				margin-bottom: 1em;
				color: $orange;
				font-weight: 400;
			}

			h4 {
				text-transform: uppercase;
				font-size: 0.75em;
				margin-bottom: 0.5em;
				color: $orange;
			}

			p {
				font-size: 1.1em;
				margin-bottom: 1em;
			}

			@include tablet {
				padding-left: 3.5vw;
				padding-right: 14vw;
			}

		}

		.double-col-figure {

			padding: 4vw 14vw;
			

			img { 
				width: 100%;
				height: auto;
			}

			@include tablet {
				padding: 4vw;
			}

		}

		.double-col-txt-img {
			@include tablet {
				flex-direction: row-reverse;
				.double-col-text {
					padding-left: 14vw;
    				padding-right: 3.5vw;
				}

			}
		}

		.double-col-small {
			.double-col-text {
				p {
					@include tablet {
						font-size: 0.8em;
					}
				}
			}
		}

	}

	.spacer	{
		height: 20vw;
	}

}