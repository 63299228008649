* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	box-sizing: border-box;
}

body {
	font-family: 'Amasis', sans-serif;
	font-weight: 300;
	font-size: 16px;

	@include desktop {
		font-size: 20px;
	}
	
}

h1, h2, h3, h4, h5, h6 {
	font-weight: 300;
}

strong {
	font-weight: 400;
}

img {
	max-width: 100%;
	height: auto;
}

a {
	text-decoration: none;
	color: black;
}

button {
	outline: none;
	border: none;
	background: transparent;
}

section {
	position: relative;
}

.content {
	overflow: hidden;
}


// PhotoSwipe

.pswp__caption__center {
	text-align: center;
}

// helpers

.mobile-hidden {
	@include mobile {
		display: none;
	}
}