$min: 3;
$max: 9;
$animation-time: 8s;
$transition-time: 1s;
$scale: 20%;
$scale-base-1: (1 + $scale / 100%);

section.cover {

	height: 100vh;
	position: relative;
	background-size: cover;
	background-position: top center;
  background-repeat: no-repeat;
  overflow: hidden;
  background: #000;

  .cover-content {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 2.4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;
    @include tablet {
      padding: 7vw;
    }

    &.projetos {
      color: #fff;
      text-align: center;
      h2 {
        font-size: 2em;
        margin-bottom: 1em;
        @include tablet {
          width: 50%;
          margin: 0 auto 1em;
        }
      }
      h1 {
        font-size: 1em;
      }
    }

  }

  .cover-video {

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //z-index: -1;
    pointer-events: none;
    overflow: hidden;

    video {
      // width: 100vw;
      // height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-height: 100vh;
      min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    }
  }
  
  .cover-slideshow {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    opacity: 0.8;
  }

  .cover-slideshow-image {
    position: absolute;
    width: 100%;
    height: 100%;
    background: no-repeat 50% 50%;
    background-size: cover;
    opacity: 1;
    transform: scale($scale-base-1);
  }

  &.cover-minimal {
    height: 100px;
    @include desktop {
      height: 30px;
    }
  }

  @for $k from $min through $max {
    .cover-slideshow-#{$k} {
      .cover-slideshow-image {
        animation-name: kenburns;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        animation-duration: $animation-time * $k;
      }
      @for $i from 1 through $k {
        .cover-slideshow-image:nth-child(#{$i}) {
          animation-name: kenburns-#{$k}-#{$i};
          z-index: ($k - $i);
        }
      }
    }
  }

}

@for $items from $min through $max {
  
  $total-time: ($animation-time * $items);
  
  @for $i from 1 through $items {
    @keyframes kenburns-#{$items}-#{$i} {
      $animation-time-percent: percentage($animation-time / $total-time);
      $transition-time-percent: percentage($transition-time / $total-time);
      
      $t1: ($animation-time-percent * ($i - 1) - $transition-time-percent / 2);
      $t2: ($animation-time-percent * ($i - 1) + $transition-time-percent / 2);
      @if($t1 < 0%) { $t1: 0%; }
      @if($t2 < 0%) { $t2: 0%; }

      $t3: ($animation-time-percent * ($i) - $transition-time-percent / 2);
      $t4: ($animation-time-percent * ($i) + $transition-time-percent / 2);
      @if($t3 > 100%) { $t3: 100%; }
      @if($t4 > 100%) { $t4: 100%; }

      $t5: (100% - $transition-time-percent / 2);
      $t6: (($t4 - $t1) * 100% / $t5);

      #{$t1} { opacity: 1; transform: scale($scale-base-1); }
      #{$t2} { opacity: 1; }
      #{$t3} { opacity: 1; }
      #{$t4} { opacity: 0; transform: scale(1); }
      
      @if($i != $items) {
        100% { opacity: 0; transform: scale($scale-base-1); }
      }
      
      @if($i == 1) {
        $scale-plus: ($scale * (100% - $t5) / $t4);
        $scale-plus-base-1: (1 + ($scale + $scale-plus) / 100%);
      
        #{$t5} { opacity: 0; transform: scale($scale-plus-base-1); }
        100% { opacity: 1; }
      }
    }
  }
}

