@charset "UTF-8";
.column {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.75rem;
}

.columns.is-mobile > .column.is-narrow {
  flex: none;
}

.columns.is-mobile > .column.is-full {
  flex: none;
  width: 100%;
}

.columns.is-mobile > .column.is-three-quarters {
  flex: none;
  width: 75%;
}

.columns.is-mobile > .column.is-two-thirds {
  flex: none;
  width: 66.6666%;
}

.columns.is-mobile > .column.is-half {
  flex: none;
  width: 50%;
}

.columns.is-mobile > .column.is-one-third {
  flex: none;
  width: 33.3333%;
}

.columns.is-mobile > .column.is-one-quarter {
  flex: none;
  width: 25%;
}

.columns.is-mobile > .column.is-one-fifth {
  flex: none;
  width: 20%;
}

.columns.is-mobile > .column.is-two-fifths {
  flex: none;
  width: 40%;
}

.columns.is-mobile > .column.is-three-fifths {
  flex: none;
  width: 60%;
}

.columns.is-mobile > .column.is-four-fifths {
  flex: none;
  width: 80%;
}

.columns.is-mobile > .column.is-offset-three-quarters {
  margin-left: 75%;
}

.columns.is-mobile > .column.is-offset-two-thirds {
  margin-left: 66.6666%;
}

.columns.is-mobile > .column.is-offset-half {
  margin-left: 50%;
}

.columns.is-mobile > .column.is-offset-one-third {
  margin-left: 33.3333%;
}

.columns.is-mobile > .column.is-offset-one-quarter {
  margin-left: 25%;
}

.columns.is-mobile > .column.is-offset-one-fifth {
  margin-left: 20%;
}

.columns.is-mobile > .column.is-offset-two-fifths {
  margin-left: 40%;
}

.columns.is-mobile > .column.is-offset-three-fifths {
  margin-left: 60%;
}

.columns.is-mobile > .column.is-offset-four-fifths {
  margin-left: 80%;
}

.columns.is-mobile > .column.is-0 {
  flex: none;
  width: 0%;
}

.columns.is-mobile > .column.is-offset-0 {
  margin-left: 0%;
}

.columns.is-mobile > .column.is-1 {
  flex: none;
  width: 8.3333333333%;
}

.columns.is-mobile > .column.is-offset-1 {
  margin-left: 8.3333333333%;
}

.columns.is-mobile > .column.is-2 {
  flex: none;
  width: 16.6666666667%;
}

.columns.is-mobile > .column.is-offset-2 {
  margin-left: 16.6666666667%;
}

.columns.is-mobile > .column.is-3 {
  flex: none;
  width: 25%;
}

.columns.is-mobile > .column.is-offset-3 {
  margin-left: 25%;
}

.columns.is-mobile > .column.is-4 {
  flex: none;
  width: 33.3333333333%;
}

.columns.is-mobile > .column.is-offset-4 {
  margin-left: 33.3333333333%;
}

.columns.is-mobile > .column.is-5 {
  flex: none;
  width: 41.6666666667%;
}

.columns.is-mobile > .column.is-offset-5 {
  margin-left: 41.6666666667%;
}

.columns.is-mobile > .column.is-6 {
  flex: none;
  width: 50%;
}

.columns.is-mobile > .column.is-offset-6 {
  margin-left: 50%;
}

.columns.is-mobile > .column.is-7 {
  flex: none;
  width: 58.3333333333%;
}

.columns.is-mobile > .column.is-offset-7 {
  margin-left: 58.3333333333%;
}

.columns.is-mobile > .column.is-8 {
  flex: none;
  width: 66.6666666667%;
}

.columns.is-mobile > .column.is-offset-8 {
  margin-left: 66.6666666667%;
}

.columns.is-mobile > .column.is-9 {
  flex: none;
  width: 75%;
}

.columns.is-mobile > .column.is-offset-9 {
  margin-left: 75%;
}

.columns.is-mobile > .column.is-10 {
  flex: none;
  width: 83.3333333333%;
}

.columns.is-mobile > .column.is-offset-10 {
  margin-left: 83.3333333333%;
}

.columns.is-mobile > .column.is-11 {
  flex: none;
  width: 91.6666666667%;
}

.columns.is-mobile > .column.is-offset-11 {
  margin-left: 91.6666666667%;
}

.columns.is-mobile > .column.is-12 {
  flex: none;
  width: 100%;
}

.columns.is-mobile > .column.is-offset-12 {
  margin-left: 100%;
}

@media screen and (max-width: 768px) {
  .column.is-narrow-mobile {
    flex: none;
  }
  .column.is-full-mobile {
    flex: none;
    width: 100%;
  }
  .column.is-three-quarters-mobile {
    flex: none;
    width: 75%;
  }
  .column.is-two-thirds-mobile {
    flex: none;
    width: 66.6666%;
  }
  .column.is-half-mobile {
    flex: none;
    width: 50%;
  }
  .column.is-one-third-mobile {
    flex: none;
    width: 33.3333%;
  }
  .column.is-one-quarter-mobile {
    flex: none;
    width: 25%;
  }
  .column.is-one-fifth-mobile {
    flex: none;
    width: 20%;
  }
  .column.is-two-fifths-mobile {
    flex: none;
    width: 40%;
  }
  .column.is-three-fifths-mobile {
    flex: none;
    width: 60%;
  }
  .column.is-four-fifths-mobile {
    flex: none;
    width: 80%;
  }
  .column.is-offset-three-quarters-mobile {
    margin-left: 75%;
  }
  .column.is-offset-two-thirds-mobile {
    margin-left: 66.6666%;
  }
  .column.is-offset-half-mobile {
    margin-left: 50%;
  }
  .column.is-offset-one-third-mobile {
    margin-left: 33.3333%;
  }
  .column.is-offset-one-quarter-mobile {
    margin-left: 25%;
  }
  .column.is-offset-one-fifth-mobile {
    margin-left: 20%;
  }
  .column.is-offset-two-fifths-mobile {
    margin-left: 40%;
  }
  .column.is-offset-three-fifths-mobile {
    margin-left: 60%;
  }
  .column.is-offset-four-fifths-mobile {
    margin-left: 80%;
  }
  .column.is-0-mobile {
    flex: none;
    width: 0%;
  }
  .column.is-offset-0-mobile {
    margin-left: 0%;
  }
  .column.is-1-mobile {
    flex: none;
    width: 8.3333333333%;
  }
  .column.is-offset-1-mobile {
    margin-left: 8.3333333333%;
  }
  .column.is-2-mobile {
    flex: none;
    width: 16.6666666667%;
  }
  .column.is-offset-2-mobile {
    margin-left: 16.6666666667%;
  }
  .column.is-3-mobile {
    flex: none;
    width: 25%;
  }
  .column.is-offset-3-mobile {
    margin-left: 25%;
  }
  .column.is-4-mobile {
    flex: none;
    width: 33.3333333333%;
  }
  .column.is-offset-4-mobile {
    margin-left: 33.3333333333%;
  }
  .column.is-5-mobile {
    flex: none;
    width: 41.6666666667%;
  }
  .column.is-offset-5-mobile {
    margin-left: 41.6666666667%;
  }
  .column.is-6-mobile {
    flex: none;
    width: 50%;
  }
  .column.is-offset-6-mobile {
    margin-left: 50%;
  }
  .column.is-7-mobile {
    flex: none;
    width: 58.3333333333%;
  }
  .column.is-offset-7-mobile {
    margin-left: 58.3333333333%;
  }
  .column.is-8-mobile {
    flex: none;
    width: 66.6666666667%;
  }
  .column.is-offset-8-mobile {
    margin-left: 66.6666666667%;
  }
  .column.is-9-mobile {
    flex: none;
    width: 75%;
  }
  .column.is-offset-9-mobile {
    margin-left: 75%;
  }
  .column.is-10-mobile {
    flex: none;
    width: 83.3333333333%;
  }
  .column.is-offset-10-mobile {
    margin-left: 83.3333333333%;
  }
  .column.is-11-mobile {
    flex: none;
    width: 91.6666666667%;
  }
  .column.is-offset-11-mobile {
    margin-left: 91.6666666667%;
  }
  .column.is-12-mobile {
    flex: none;
    width: 100%;
  }
  .column.is-offset-12-mobile {
    margin-left: 100%;
  }
}

@media screen and (min-width: 769px), print {
  .column.is-narrow, .column.is-narrow-tablet {
    flex: none;
  }
  .column.is-full, .column.is-full-tablet {
    flex: none;
    width: 100%;
  }
  .column.is-three-quarters, .column.is-three-quarters-tablet {
    flex: none;
    width: 75%;
  }
  .column.is-two-thirds, .column.is-two-thirds-tablet {
    flex: none;
    width: 66.6666%;
  }
  .column.is-half, .column.is-half-tablet {
    flex: none;
    width: 50%;
  }
  .column.is-one-third, .column.is-one-third-tablet {
    flex: none;
    width: 33.3333%;
  }
  .column.is-one-quarter, .column.is-one-quarter-tablet {
    flex: none;
    width: 25%;
  }
  .column.is-one-fifth, .column.is-one-fifth-tablet {
    flex: none;
    width: 20%;
  }
  .column.is-two-fifths, .column.is-two-fifths-tablet {
    flex: none;
    width: 40%;
  }
  .column.is-three-fifths, .column.is-three-fifths-tablet {
    flex: none;
    width: 60%;
  }
  .column.is-four-fifths, .column.is-four-fifths-tablet {
    flex: none;
    width: 80%;
  }
  .column.is-offset-three-quarters, .column.is-offset-three-quarters-tablet {
    margin-left: 75%;
  }
  .column.is-offset-two-thirds, .column.is-offset-two-thirds-tablet {
    margin-left: 66.6666%;
  }
  .column.is-offset-half, .column.is-offset-half-tablet {
    margin-left: 50%;
  }
  .column.is-offset-one-third, .column.is-offset-one-third-tablet {
    margin-left: 33.3333%;
  }
  .column.is-offset-one-quarter, .column.is-offset-one-quarter-tablet {
    margin-left: 25%;
  }
  .column.is-offset-one-fifth, .column.is-offset-one-fifth-tablet {
    margin-left: 20%;
  }
  .column.is-offset-two-fifths, .column.is-offset-two-fifths-tablet {
    margin-left: 40%;
  }
  .column.is-offset-three-fifths, .column.is-offset-three-fifths-tablet {
    margin-left: 60%;
  }
  .column.is-offset-four-fifths, .column.is-offset-four-fifths-tablet {
    margin-left: 80%;
  }
  .column.is-0, .column.is-0-tablet {
    flex: none;
    width: 0%;
  }
  .column.is-offset-0, .column.is-offset-0-tablet {
    margin-left: 0%;
  }
  .column.is-1, .column.is-1-tablet {
    flex: none;
    width: 8.3333333333%;
  }
  .column.is-offset-1, .column.is-offset-1-tablet {
    margin-left: 8.3333333333%;
  }
  .column.is-2, .column.is-2-tablet {
    flex: none;
    width: 16.6666666667%;
  }
  .column.is-offset-2, .column.is-offset-2-tablet {
    margin-left: 16.6666666667%;
  }
  .column.is-3, .column.is-3-tablet {
    flex: none;
    width: 25%;
  }
  .column.is-offset-3, .column.is-offset-3-tablet {
    margin-left: 25%;
  }
  .column.is-4, .column.is-4-tablet {
    flex: none;
    width: 33.3333333333%;
  }
  .column.is-offset-4, .column.is-offset-4-tablet {
    margin-left: 33.3333333333%;
  }
  .column.is-5, .column.is-5-tablet {
    flex: none;
    width: 41.6666666667%;
  }
  .column.is-offset-5, .column.is-offset-5-tablet {
    margin-left: 41.6666666667%;
  }
  .column.is-6, .column.is-6-tablet {
    flex: none;
    width: 50%;
  }
  .column.is-offset-6, .column.is-offset-6-tablet {
    margin-left: 50%;
  }
  .column.is-7, .column.is-7-tablet {
    flex: none;
    width: 58.3333333333%;
  }
  .column.is-offset-7, .column.is-offset-7-tablet {
    margin-left: 58.3333333333%;
  }
  .column.is-8, .column.is-8-tablet {
    flex: none;
    width: 66.6666666667%;
  }
  .column.is-offset-8, .column.is-offset-8-tablet {
    margin-left: 66.6666666667%;
  }
  .column.is-9, .column.is-9-tablet {
    flex: none;
    width: 75%;
  }
  .column.is-offset-9, .column.is-offset-9-tablet {
    margin-left: 75%;
  }
  .column.is-10, .column.is-10-tablet {
    flex: none;
    width: 83.3333333333%;
  }
  .column.is-offset-10, .column.is-offset-10-tablet {
    margin-left: 83.3333333333%;
  }
  .column.is-11, .column.is-11-tablet {
    flex: none;
    width: 91.6666666667%;
  }
  .column.is-offset-11, .column.is-offset-11-tablet {
    margin-left: 91.6666666667%;
  }
  .column.is-12, .column.is-12-tablet {
    flex: none;
    width: 100%;
  }
  .column.is-offset-12, .column.is-offset-12-tablet {
    margin-left: 100%;
  }
}

@media screen and (max-width: 1023px) {
  .column.is-narrow-touch {
    flex: none;
  }
  .column.is-full-touch {
    flex: none;
    width: 100%;
  }
  .column.is-three-quarters-touch {
    flex: none;
    width: 75%;
  }
  .column.is-two-thirds-touch {
    flex: none;
    width: 66.6666%;
  }
  .column.is-half-touch {
    flex: none;
    width: 50%;
  }
  .column.is-one-third-touch {
    flex: none;
    width: 33.3333%;
  }
  .column.is-one-quarter-touch {
    flex: none;
    width: 25%;
  }
  .column.is-one-fifth-touch {
    flex: none;
    width: 20%;
  }
  .column.is-two-fifths-touch {
    flex: none;
    width: 40%;
  }
  .column.is-three-fifths-touch {
    flex: none;
    width: 60%;
  }
  .column.is-four-fifths-touch {
    flex: none;
    width: 80%;
  }
  .column.is-offset-three-quarters-touch {
    margin-left: 75%;
  }
  .column.is-offset-two-thirds-touch {
    margin-left: 66.6666%;
  }
  .column.is-offset-half-touch {
    margin-left: 50%;
  }
  .column.is-offset-one-third-touch {
    margin-left: 33.3333%;
  }
  .column.is-offset-one-quarter-touch {
    margin-left: 25%;
  }
  .column.is-offset-one-fifth-touch {
    margin-left: 20%;
  }
  .column.is-offset-two-fifths-touch {
    margin-left: 40%;
  }
  .column.is-offset-three-fifths-touch {
    margin-left: 60%;
  }
  .column.is-offset-four-fifths-touch {
    margin-left: 80%;
  }
  .column.is-0-touch {
    flex: none;
    width: 0%;
  }
  .column.is-offset-0-touch {
    margin-left: 0%;
  }
  .column.is-1-touch {
    flex: none;
    width: 8.3333333333%;
  }
  .column.is-offset-1-touch {
    margin-left: 8.3333333333%;
  }
  .column.is-2-touch {
    flex: none;
    width: 16.6666666667%;
  }
  .column.is-offset-2-touch {
    margin-left: 16.6666666667%;
  }
  .column.is-3-touch {
    flex: none;
    width: 25%;
  }
  .column.is-offset-3-touch {
    margin-left: 25%;
  }
  .column.is-4-touch {
    flex: none;
    width: 33.3333333333%;
  }
  .column.is-offset-4-touch {
    margin-left: 33.3333333333%;
  }
  .column.is-5-touch {
    flex: none;
    width: 41.6666666667%;
  }
  .column.is-offset-5-touch {
    margin-left: 41.6666666667%;
  }
  .column.is-6-touch {
    flex: none;
    width: 50%;
  }
  .column.is-offset-6-touch {
    margin-left: 50%;
  }
  .column.is-7-touch {
    flex: none;
    width: 58.3333333333%;
  }
  .column.is-offset-7-touch {
    margin-left: 58.3333333333%;
  }
  .column.is-8-touch {
    flex: none;
    width: 66.6666666667%;
  }
  .column.is-offset-8-touch {
    margin-left: 66.6666666667%;
  }
  .column.is-9-touch {
    flex: none;
    width: 75%;
  }
  .column.is-offset-9-touch {
    margin-left: 75%;
  }
  .column.is-10-touch {
    flex: none;
    width: 83.3333333333%;
  }
  .column.is-offset-10-touch {
    margin-left: 83.3333333333%;
  }
  .column.is-11-touch {
    flex: none;
    width: 91.6666666667%;
  }
  .column.is-offset-11-touch {
    margin-left: 91.6666666667%;
  }
  .column.is-12-touch {
    flex: none;
    width: 100%;
  }
  .column.is-offset-12-touch {
    margin-left: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .column.is-narrow-desktop {
    flex: none;
  }
  .column.is-full-desktop {
    flex: none;
    width: 100%;
  }
  .column.is-three-quarters-desktop {
    flex: none;
    width: 75%;
  }
  .column.is-two-thirds-desktop {
    flex: none;
    width: 66.6666%;
  }
  .column.is-half-desktop {
    flex: none;
    width: 50%;
  }
  .column.is-one-third-desktop {
    flex: none;
    width: 33.3333%;
  }
  .column.is-one-quarter-desktop {
    flex: none;
    width: 25%;
  }
  .column.is-one-fifth-desktop {
    flex: none;
    width: 20%;
  }
  .column.is-two-fifths-desktop {
    flex: none;
    width: 40%;
  }
  .column.is-three-fifths-desktop {
    flex: none;
    width: 60%;
  }
  .column.is-four-fifths-desktop {
    flex: none;
    width: 80%;
  }
  .column.is-offset-three-quarters-desktop {
    margin-left: 75%;
  }
  .column.is-offset-two-thirds-desktop {
    margin-left: 66.6666%;
  }
  .column.is-offset-half-desktop {
    margin-left: 50%;
  }
  .column.is-offset-one-third-desktop {
    margin-left: 33.3333%;
  }
  .column.is-offset-one-quarter-desktop {
    margin-left: 25%;
  }
  .column.is-offset-one-fifth-desktop {
    margin-left: 20%;
  }
  .column.is-offset-two-fifths-desktop {
    margin-left: 40%;
  }
  .column.is-offset-three-fifths-desktop {
    margin-left: 60%;
  }
  .column.is-offset-four-fifths-desktop {
    margin-left: 80%;
  }
  .column.is-0-desktop {
    flex: none;
    width: 0%;
  }
  .column.is-offset-0-desktop {
    margin-left: 0%;
  }
  .column.is-1-desktop {
    flex: none;
    width: 8.3333333333%;
  }
  .column.is-offset-1-desktop {
    margin-left: 8.3333333333%;
  }
  .column.is-2-desktop {
    flex: none;
    width: 16.6666666667%;
  }
  .column.is-offset-2-desktop {
    margin-left: 16.6666666667%;
  }
  .column.is-3-desktop {
    flex: none;
    width: 25%;
  }
  .column.is-offset-3-desktop {
    margin-left: 25%;
  }
  .column.is-4-desktop {
    flex: none;
    width: 33.3333333333%;
  }
  .column.is-offset-4-desktop {
    margin-left: 33.3333333333%;
  }
  .column.is-5-desktop {
    flex: none;
    width: 41.6666666667%;
  }
  .column.is-offset-5-desktop {
    margin-left: 41.6666666667%;
  }
  .column.is-6-desktop {
    flex: none;
    width: 50%;
  }
  .column.is-offset-6-desktop {
    margin-left: 50%;
  }
  .column.is-7-desktop {
    flex: none;
    width: 58.3333333333%;
  }
  .column.is-offset-7-desktop {
    margin-left: 58.3333333333%;
  }
  .column.is-8-desktop {
    flex: none;
    width: 66.6666666667%;
  }
  .column.is-offset-8-desktop {
    margin-left: 66.6666666667%;
  }
  .column.is-9-desktop {
    flex: none;
    width: 75%;
  }
  .column.is-offset-9-desktop {
    margin-left: 75%;
  }
  .column.is-10-desktop {
    flex: none;
    width: 83.3333333333%;
  }
  .column.is-offset-10-desktop {
    margin-left: 83.3333333333%;
  }
  .column.is-11-desktop {
    flex: none;
    width: 91.6666666667%;
  }
  .column.is-offset-11-desktop {
    margin-left: 91.6666666667%;
  }
  .column.is-12-desktop {
    flex: none;
    width: 100%;
  }
  .column.is-offset-12-desktop {
    margin-left: 100%;
  }
}

@media screen and (min-width: 1216px) {
  .column.is-narrow-widescreen {
    flex: none;
  }
  .column.is-full-widescreen {
    flex: none;
    width: 100%;
  }
  .column.is-three-quarters-widescreen {
    flex: none;
    width: 75%;
  }
  .column.is-two-thirds-widescreen {
    flex: none;
    width: 66.6666%;
  }
  .column.is-half-widescreen {
    flex: none;
    width: 50%;
  }
  .column.is-one-third-widescreen {
    flex: none;
    width: 33.3333%;
  }
  .column.is-one-quarter-widescreen {
    flex: none;
    width: 25%;
  }
  .column.is-one-fifth-widescreen {
    flex: none;
    width: 20%;
  }
  .column.is-two-fifths-widescreen {
    flex: none;
    width: 40%;
  }
  .column.is-three-fifths-widescreen {
    flex: none;
    width: 60%;
  }
  .column.is-four-fifths-widescreen {
    flex: none;
    width: 80%;
  }
  .column.is-offset-three-quarters-widescreen {
    margin-left: 75%;
  }
  .column.is-offset-two-thirds-widescreen {
    margin-left: 66.6666%;
  }
  .column.is-offset-half-widescreen {
    margin-left: 50%;
  }
  .column.is-offset-one-third-widescreen {
    margin-left: 33.3333%;
  }
  .column.is-offset-one-quarter-widescreen {
    margin-left: 25%;
  }
  .column.is-offset-one-fifth-widescreen {
    margin-left: 20%;
  }
  .column.is-offset-two-fifths-widescreen {
    margin-left: 40%;
  }
  .column.is-offset-three-fifths-widescreen {
    margin-left: 60%;
  }
  .column.is-offset-four-fifths-widescreen {
    margin-left: 80%;
  }
  .column.is-0-widescreen {
    flex: none;
    width: 0%;
  }
  .column.is-offset-0-widescreen {
    margin-left: 0%;
  }
  .column.is-1-widescreen {
    flex: none;
    width: 8.3333333333%;
  }
  .column.is-offset-1-widescreen {
    margin-left: 8.3333333333%;
  }
  .column.is-2-widescreen {
    flex: none;
    width: 16.6666666667%;
  }
  .column.is-offset-2-widescreen {
    margin-left: 16.6666666667%;
  }
  .column.is-3-widescreen {
    flex: none;
    width: 25%;
  }
  .column.is-offset-3-widescreen {
    margin-left: 25%;
  }
  .column.is-4-widescreen {
    flex: none;
    width: 33.3333333333%;
  }
  .column.is-offset-4-widescreen {
    margin-left: 33.3333333333%;
  }
  .column.is-5-widescreen {
    flex: none;
    width: 41.6666666667%;
  }
  .column.is-offset-5-widescreen {
    margin-left: 41.6666666667%;
  }
  .column.is-6-widescreen {
    flex: none;
    width: 50%;
  }
  .column.is-offset-6-widescreen {
    margin-left: 50%;
  }
  .column.is-7-widescreen {
    flex: none;
    width: 58.3333333333%;
  }
  .column.is-offset-7-widescreen {
    margin-left: 58.3333333333%;
  }
  .column.is-8-widescreen {
    flex: none;
    width: 66.6666666667%;
  }
  .column.is-offset-8-widescreen {
    margin-left: 66.6666666667%;
  }
  .column.is-9-widescreen {
    flex: none;
    width: 75%;
  }
  .column.is-offset-9-widescreen {
    margin-left: 75%;
  }
  .column.is-10-widescreen {
    flex: none;
    width: 83.3333333333%;
  }
  .column.is-offset-10-widescreen {
    margin-left: 83.3333333333%;
  }
  .column.is-11-widescreen {
    flex: none;
    width: 91.6666666667%;
  }
  .column.is-offset-11-widescreen {
    margin-left: 91.6666666667%;
  }
  .column.is-12-widescreen {
    flex: none;
    width: 100%;
  }
  .column.is-offset-12-widescreen {
    margin-left: 100%;
  }
}

@media screen and (min-width: 1408px) {
  .column.is-narrow-fullhd {
    flex: none;
  }
  .column.is-full-fullhd {
    flex: none;
    width: 100%;
  }
  .column.is-three-quarters-fullhd {
    flex: none;
    width: 75%;
  }
  .column.is-two-thirds-fullhd {
    flex: none;
    width: 66.6666%;
  }
  .column.is-half-fullhd {
    flex: none;
    width: 50%;
  }
  .column.is-one-third-fullhd {
    flex: none;
    width: 33.3333%;
  }
  .column.is-one-quarter-fullhd {
    flex: none;
    width: 25%;
  }
  .column.is-one-fifth-fullhd {
    flex: none;
    width: 20%;
  }
  .column.is-two-fifths-fullhd {
    flex: none;
    width: 40%;
  }
  .column.is-three-fifths-fullhd {
    flex: none;
    width: 60%;
  }
  .column.is-four-fifths-fullhd {
    flex: none;
    width: 80%;
  }
  .column.is-offset-three-quarters-fullhd {
    margin-left: 75%;
  }
  .column.is-offset-two-thirds-fullhd {
    margin-left: 66.6666%;
  }
  .column.is-offset-half-fullhd {
    margin-left: 50%;
  }
  .column.is-offset-one-third-fullhd {
    margin-left: 33.3333%;
  }
  .column.is-offset-one-quarter-fullhd {
    margin-left: 25%;
  }
  .column.is-offset-one-fifth-fullhd {
    margin-left: 20%;
  }
  .column.is-offset-two-fifths-fullhd {
    margin-left: 40%;
  }
  .column.is-offset-three-fifths-fullhd {
    margin-left: 60%;
  }
  .column.is-offset-four-fifths-fullhd {
    margin-left: 80%;
  }
  .column.is-0-fullhd {
    flex: none;
    width: 0%;
  }
  .column.is-offset-0-fullhd {
    margin-left: 0%;
  }
  .column.is-1-fullhd {
    flex: none;
    width: 8.3333333333%;
  }
  .column.is-offset-1-fullhd {
    margin-left: 8.3333333333%;
  }
  .column.is-2-fullhd {
    flex: none;
    width: 16.6666666667%;
  }
  .column.is-offset-2-fullhd {
    margin-left: 16.6666666667%;
  }
  .column.is-3-fullhd {
    flex: none;
    width: 25%;
  }
  .column.is-offset-3-fullhd {
    margin-left: 25%;
  }
  .column.is-4-fullhd {
    flex: none;
    width: 33.3333333333%;
  }
  .column.is-offset-4-fullhd {
    margin-left: 33.3333333333%;
  }
  .column.is-5-fullhd {
    flex: none;
    width: 41.6666666667%;
  }
  .column.is-offset-5-fullhd {
    margin-left: 41.6666666667%;
  }
  .column.is-6-fullhd {
    flex: none;
    width: 50%;
  }
  .column.is-offset-6-fullhd {
    margin-left: 50%;
  }
  .column.is-7-fullhd {
    flex: none;
    width: 58.3333333333%;
  }
  .column.is-offset-7-fullhd {
    margin-left: 58.3333333333%;
  }
  .column.is-8-fullhd {
    flex: none;
    width: 66.6666666667%;
  }
  .column.is-offset-8-fullhd {
    margin-left: 66.6666666667%;
  }
  .column.is-9-fullhd {
    flex: none;
    width: 75%;
  }
  .column.is-offset-9-fullhd {
    margin-left: 75%;
  }
  .column.is-10-fullhd {
    flex: none;
    width: 83.3333333333%;
  }
  .column.is-offset-10-fullhd {
    margin-left: 83.3333333333%;
  }
  .column.is-11-fullhd {
    flex: none;
    width: 91.6666666667%;
  }
  .column.is-offset-11-fullhd {
    margin-left: 91.6666666667%;
  }
  .column.is-12-fullhd {
    flex: none;
    width: 100%;
  }
  .column.is-offset-12-fullhd {
    margin-left: 100%;
  }
}

.columns {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  margin-top: -0.75rem;
}

.columns:last-child {
  margin-bottom: -0.75rem;
}

.columns:not(:last-child) {
  margin-bottom: calc(1.5rem - 0.75rem);
}

.columns.is-centered {
  justify-content: center;
}

.columns.is-gapless {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
}

.columns.is-gapless > .column {
  margin: 0;
  padding: 0 !important;
}

.columns.is-gapless:not(:last-child) {
  margin-bottom: 1.5rem;
}

.columns.is-gapless:last-child {
  margin-bottom: 0;
}

.columns.is-mobile {
  display: flex;
}

.columns.is-multiline {
  flex-wrap: wrap;
}

.columns.is-vcentered {
  align-items: center;
}

@media screen and (min-width: 769px), print {
  .columns:not(.is-desktop) {
    display: flex;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-desktop {
    display: flex;
  }
}

.columns.is-variable {
  --columnGap: 0.75rem;
  margin-left: calc(-1 * var(--columnGap));
  margin-right: calc(-1 * var(--columnGap));
}

.columns.is-variable .column {
  padding-left: var(--columnGap);
  padding-right: var(--columnGap);
}

.columns.is-variable.is-0 {
  --columnGap: 0rem;
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-0-mobile {
    --columnGap: 0rem;
  }
}

@media screen and (min-width: 769px), print {
  .columns.is-variable.is-0-tablet {
    --columnGap: 0rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-0-tablet-only {
    --columnGap: 0rem;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-variable.is-0-touch {
    --columnGap: 0rem;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-variable.is-0-desktop {
    --columnGap: 0rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-0-desktop-only {
    --columnGap: 0rem;
  }
}

@media screen and (min-width: 1216px) {
  .columns.is-variable.is-0-widescreen {
    --columnGap: 0rem;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-0-widescreen-only {
    --columnGap: 0rem;
  }
}

@media screen and (min-width: 1408px) {
  .columns.is-variable.is-0-fullhd {
    --columnGap: 0rem;
  }
}

.columns.is-variable.is-1 {
  --columnGap: 0.25rem;
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-1-mobile {
    --columnGap: 0.25rem;
  }
}

@media screen and (min-width: 769px), print {
  .columns.is-variable.is-1-tablet {
    --columnGap: 0.25rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-1-tablet-only {
    --columnGap: 0.25rem;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-variable.is-1-touch {
    --columnGap: 0.25rem;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-variable.is-1-desktop {
    --columnGap: 0.25rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-1-desktop-only {
    --columnGap: 0.25rem;
  }
}

@media screen and (min-width: 1216px) {
  .columns.is-variable.is-1-widescreen {
    --columnGap: 0.25rem;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-1-widescreen-only {
    --columnGap: 0.25rem;
  }
}

@media screen and (min-width: 1408px) {
  .columns.is-variable.is-1-fullhd {
    --columnGap: 0.25rem;
  }
}

.columns.is-variable.is-2 {
  --columnGap: 0.5rem;
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-2-mobile {
    --columnGap: 0.5rem;
  }
}

@media screen and (min-width: 769px), print {
  .columns.is-variable.is-2-tablet {
    --columnGap: 0.5rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-2-tablet-only {
    --columnGap: 0.5rem;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-variable.is-2-touch {
    --columnGap: 0.5rem;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-variable.is-2-desktop {
    --columnGap: 0.5rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-2-desktop-only {
    --columnGap: 0.5rem;
  }
}

@media screen and (min-width: 1216px) {
  .columns.is-variable.is-2-widescreen {
    --columnGap: 0.5rem;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-2-widescreen-only {
    --columnGap: 0.5rem;
  }
}

@media screen and (min-width: 1408px) {
  .columns.is-variable.is-2-fullhd {
    --columnGap: 0.5rem;
  }
}

.columns.is-variable.is-3 {
  --columnGap: 0.75rem;
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-3-mobile {
    --columnGap: 0.75rem;
  }
}

@media screen and (min-width: 769px), print {
  .columns.is-variable.is-3-tablet {
    --columnGap: 0.75rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-3-tablet-only {
    --columnGap: 0.75rem;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-variable.is-3-touch {
    --columnGap: 0.75rem;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-variable.is-3-desktop {
    --columnGap: 0.75rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-3-desktop-only {
    --columnGap: 0.75rem;
  }
}

@media screen and (min-width: 1216px) {
  .columns.is-variable.is-3-widescreen {
    --columnGap: 0.75rem;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-3-widescreen-only {
    --columnGap: 0.75rem;
  }
}

@media screen and (min-width: 1408px) {
  .columns.is-variable.is-3-fullhd {
    --columnGap: 0.75rem;
  }
}

.columns.is-variable.is-4 {
  --columnGap: 1rem;
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-4-mobile {
    --columnGap: 1rem;
  }
}

@media screen and (min-width: 769px), print {
  .columns.is-variable.is-4-tablet {
    --columnGap: 1rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-4-tablet-only {
    --columnGap: 1rem;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-variable.is-4-touch {
    --columnGap: 1rem;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-variable.is-4-desktop {
    --columnGap: 1rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-4-desktop-only {
    --columnGap: 1rem;
  }
}

@media screen and (min-width: 1216px) {
  .columns.is-variable.is-4-widescreen {
    --columnGap: 1rem;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-4-widescreen-only {
    --columnGap: 1rem;
  }
}

@media screen and (min-width: 1408px) {
  .columns.is-variable.is-4-fullhd {
    --columnGap: 1rem;
  }
}

.columns.is-variable.is-5 {
  --columnGap: 1.25rem;
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-5-mobile {
    --columnGap: 1.25rem;
  }
}

@media screen and (min-width: 769px), print {
  .columns.is-variable.is-5-tablet {
    --columnGap: 1.25rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-5-tablet-only {
    --columnGap: 1.25rem;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-variable.is-5-touch {
    --columnGap: 1.25rem;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-variable.is-5-desktop {
    --columnGap: 1.25rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-5-desktop-only {
    --columnGap: 1.25rem;
  }
}

@media screen and (min-width: 1216px) {
  .columns.is-variable.is-5-widescreen {
    --columnGap: 1.25rem;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-5-widescreen-only {
    --columnGap: 1.25rem;
  }
}

@media screen and (min-width: 1408px) {
  .columns.is-variable.is-5-fullhd {
    --columnGap: 1.25rem;
  }
}

.columns.is-variable.is-6 {
  --columnGap: 1.5rem;
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-6-mobile {
    --columnGap: 1.5rem;
  }
}

@media screen and (min-width: 769px), print {
  .columns.is-variable.is-6-tablet {
    --columnGap: 1.5rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-6-tablet-only {
    --columnGap: 1.5rem;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-variable.is-6-touch {
    --columnGap: 1.5rem;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-variable.is-6-desktop {
    --columnGap: 1.5rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-6-desktop-only {
    --columnGap: 1.5rem;
  }
}

@media screen and (min-width: 1216px) {
  .columns.is-variable.is-6-widescreen {
    --columnGap: 1.5rem;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-6-widescreen-only {
    --columnGap: 1.5rem;
  }
}

@media screen and (min-width: 1408px) {
  .columns.is-variable.is-6-fullhd {
    --columnGap: 1.5rem;
  }
}

.columns.is-variable.is-7 {
  --columnGap: 1.75rem;
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-7-mobile {
    --columnGap: 1.75rem;
  }
}

@media screen and (min-width: 769px), print {
  .columns.is-variable.is-7-tablet {
    --columnGap: 1.75rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-7-tablet-only {
    --columnGap: 1.75rem;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-variable.is-7-touch {
    --columnGap: 1.75rem;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-variable.is-7-desktop {
    --columnGap: 1.75rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-7-desktop-only {
    --columnGap: 1.75rem;
  }
}

@media screen and (min-width: 1216px) {
  .columns.is-variable.is-7-widescreen {
    --columnGap: 1.75rem;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-7-widescreen-only {
    --columnGap: 1.75rem;
  }
}

@media screen and (min-width: 1408px) {
  .columns.is-variable.is-7-fullhd {
    --columnGap: 1.75rem;
  }
}

.columns.is-variable.is-8 {
  --columnGap: 2rem;
}

@media screen and (max-width: 768px) {
  .columns.is-variable.is-8-mobile {
    --columnGap: 2rem;
  }
}

@media screen and (min-width: 769px), print {
  .columns.is-variable.is-8-tablet {
    --columnGap: 2rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .columns.is-variable.is-8-tablet-only {
    --columnGap: 2rem;
  }
}

@media screen and (max-width: 1023px) {
  .columns.is-variable.is-8-touch {
    --columnGap: 2rem;
  }
}

@media screen and (min-width: 1024px) {
  .columns.is-variable.is-8-desktop {
    --columnGap: 2rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .columns.is-variable.is-8-desktop-only {
    --columnGap: 2rem;
  }
}

@media screen and (min-width: 1216px) {
  .columns.is-variable.is-8-widescreen {
    --columnGap: 2rem;
  }
}

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .columns.is-variable.is-8-widescreen-only {
    --columnGap: 2rem;
  }
}

@media screen and (min-width: 1408px) {
  .columns.is-variable.is-8-fullhd {
    --columnGap: 2rem;
  }
}

.tile {
  align-items: stretch;
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  min-height: min-content;
}

.tile.is-ancestor {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  margin-top: -0.75rem;
}

.tile.is-ancestor:last-child {
  margin-bottom: -0.75rem;
}

.tile.is-ancestor:not(:last-child) {
  margin-bottom: 0.75rem;
}

.tile.is-child {
  margin: 0 !important;
}

.tile.is-parent {
  padding: 0.75rem;
}

.tile.is-vertical {
  flex-direction: column;
}

.tile.is-vertical > .tile.is-child:not(:last-child) {
  margin-bottom: 1.5rem !important;
}

@media screen and (min-width: 769px), print {
  .tile:not(.is-child) {
    display: flex;
  }
  .tile.is-1 {
    flex: none;
    width: 8.3333333333%;
  }
  .tile.is-2 {
    flex: none;
    width: 16.6666666667%;
  }
  .tile.is-3 {
    flex: none;
    width: 25%;
  }
  .tile.is-4 {
    flex: none;
    width: 33.3333333333%;
  }
  .tile.is-5 {
    flex: none;
    width: 41.6666666667%;
  }
  .tile.is-6 {
    flex: none;
    width: 50%;
  }
  .tile.is-7 {
    flex: none;
    width: 58.3333333333%;
  }
  .tile.is-8 {
    flex: none;
    width: 66.6666666667%;
  }
  .tile.is-9 {
    flex: none;
    width: 75%;
  }
  .tile.is-10 {
    flex: none;
    width: 83.3333333333%;
  }
  .tile.is-11 {
    flex: none;
    width: 91.6666666667%;
  }
  .tile.is-12 {
    flex: none;
    width: 100%;
  }
}

@font-face {
  font-family: "Amasis";
  src: url("../assets/fonts/Amasis-Light.woff2") format("woff2"), url("../assets/fonts/Amasis-Light.woff") format("woff"), url("../assets/fonts/Amasis-Light.otf") format("opentype");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Amasis";
  src: url("../assets/fonts/Amasis-Regular.woff2") format("woff2"), url("../assets/fonts/Amasis-Regular.woff") format("woff"), url("../assets/fonts/Amasis-Regular.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

body {
  font-family: 'Amasis', sans-serif;
  font-weight: 300;
  font-size: 16px;
}

@media screen and (min-width: 1024px) {
  body {
    font-size: 20px;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 300;
}

strong {
  font-weight: 400;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  color: black;
}

button {
  outline: none;
  border: none;
  background: transparent;
}

section {
  position: relative;
}

.content {
  overflow: hidden;
}

.pswp__caption__center {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .mobile-hidden {
    display: none;
  }
}

.header {
  z-index: 20;
  position: fixed;
  top: 0;
  left: 0;
}

.header .header-logo {
  position: absolute;
  top: 50px;
  left: 50px;
  width: 128px;
  height: 128px;
  margin-left: -64px;
  margin-top: -64px;
  transition: all .5s;
  z-index: 21;
  transform: scale(0.75);
}

@media screen and (min-width: 1024px) {
  .header .header-logo {
    top: 75px;
    left: 90px;
    width: 128px;
    transform: scale(1);
  }
}

.header .header-logo img {
  position: absolute;
  top: 0;
  left: 0;
  height: 128px;
  width: 128px;
  transition: all .5s;
}

body.top-is-cover:not(.show-menu):not(.single-graviola-projetos) .header .header-logo {
  transform: translate3d(0, -300px, 0);
}

.header .header-logo #logo-mask, .header .header-logo #logo-clip {
  transition: transform .5s, opacity .5s;
  transform-origin: 50% 50%;
}

body.single-graviola-projetos.top-is-cover .header .header-logo #logo-mask, body.single-graviola-projetos.top-is-cover .header .header-logo #logo-clip,
body.show-menu .header .header-logo #logo-mask,
body.show-menu .header .header-logo #logo-clip,
body.top-is-white .header .header-logo #logo-mask,
body.top-is-white .header .header-logo #logo-clip,
body.top-is-footer .header .header-logo #logo-mask,
body.top-is-footer .header .header-logo #logo-clip {
  transform: scale(1.5);
}

body.single-graviola-projetos.top-is-cover .header .header-logo #logo-clip,
body.show-menu .header .header-logo #logo-clip,
body.top-is-white .header .header-logo #logo-clip,
body.top-is-footer .header .header-logo #logo-clip {
  opacity: 0;
}

.header .header-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transform: translate3d(0, -100vh, 0);
  background-color: rgba(103, 107, 102, 0.95);
  transition: all .5s;
  cursor: pointer;
  z-index: 20;
}

body.show-menu .header .header-menu {
  transform: translate3d(0, 0, 0);
}

.header .header-menu .header-menu-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.header .header-menu .header-menu-links {
  list-style: none;
  margin: 0;
  padding: 0;
}

.header .header-menu .header-menu-links li {
  height: 4em;
  text-align: center;
}

.header .header-menu .header-menu-links li a {
  color: #ffffff;
  padding-bottom: 0;
  border-bottom: 1px solid transparent;
  transition: all 0.5s;
  font-size: 2em;
  font-weight: 400;
}

.header .header-menu .header-menu-links li a:hover, .header .header-menu .header-menu-links li a.active, a:hover .header .header-menu .header-menu-links li a, a.active .header .header-menu .header-menu-links li a {
  padding-bottom: 0.2em;
  border-bottom: 1px solid #ffffff;
}

.header .header-menu .header-menu-social {
  list-style: none;
  margin: 20px 0 0;
  padding: 0;
}

.header .header-menu .header-menu-social li {
  display: inline-block;
  margin: 0 10px;
}

.header .header-menu .header-menu-social li a {
  transition: all 0.5s;
  display: block;
}

.header .header-menu .header-menu-social li a:hover {
  transform: scale(1.2);
}

.header .header-menu .header-menu-social li a svg {
  fill: #ffffff;
}

.icon-burger {
  position: fixed;
  top: 40px;
  right: 30px;
  width: 40px;
  height: 24px;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  user-select: none;
  z-index: 21;
}

@media screen and (min-width: 1024px) {
  .icon-burger {
    top: 64px;
    right: 60px;
  }
}

.icon-burger span {
  margin: 0;
  padding: 0;
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #676B66;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

.icon-burger span:nth-child(1) {
  top: 0px;
}

.icon-burger span:nth-child(2),
.icon-burger span:nth-child(3) {
  top: 12px;
}

.icon-burger span:nth-child(4) {
  top: 24px;
}

body.top-is-footer .icon-burger span,
body.top-is-cover .icon-burger span,
body.top-is-white .icon-burger span,
body.show-menu .icon-burger span {
  background: #eee;
}

body.show-menu .icon-burger span:nth-child(1) {
  top: 12px;
  width: 0%;
  left: 50%;
}

body.show-menu .icon-burger span:nth-child(2) {
  transform: rotate(45deg);
}

body.show-menu .icon-burger span:nth-child(3) {
  transform: rotate(-45deg);
}

body.show-menu .icon-burger span:nth-child(4) {
  top: 12px;
  width: 0%;
  left: 50%;
}

.page-title {
  padding: 30px 60px 0;
}

@media screen and (min-width: 1024px) {
  .page-title {
    margin: 60px 120px 0;
  }
}

.page-title h1, .page-title h2 {
  color: #666;
}

.page-title h1 {
  font-size: 4em;
}

body.search,
body.page-template-page-blog,
body.single-post {
  background-color: #f9f9f9;
}

.searchform .search-wrapper {
  display: flex;
}

.searchform input[type="text"] {
  font-family: "Amasis";
  font-weight: 400;
  font-size: 1.2em;
  padding: 0.7em;
  border: none;
  color: #666;
  flex-grow: 1;
}

.searchform input[type="submit"] {
  background: url(../assets/images/search.svg) no-repeat center center;
  background-size: cover;
  width: 50px;
  height: 50px;
  margin: 0;
  margin-left: -25px;
  padding: 0;
  border: none;
  cursor: pointer;
}

.searchform input[type="submit"]:hover {
  opacity: 0.8;
}

.search-mobile {
  padding: 30px 60px 0;
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 1024px) {
  .search-mobile {
    display: none;
  }
}

.search-mobile .searchform {
  max-width: 640px;
  margin: 0 auto;
}

.blog-wrapper {
  padding: 30px 60px 0;
}

@media screen and (min-width: 1216px) {
  .blog-wrapper {
    margin: 0 120px 0;
  }
}

.blog-wrapper article {
  margin: 20px auto;
  background: #fff;
  padding: 20px;
  max-width: 640px;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .blog-wrapper article {
    max-width: 360px;
  }
  .blog-wrapper article img {
    width: 100%;
    height: auto;
  }
}

@media screen and (min-width: 1024px) {
  .blog-wrapper article {
    margin: 20px 0;
  }
}

.blog-wrapper article h3 {
  font-size: 1.5em;
  margin: 0.2em 0;
}

.blog-wrapper article h6 {
  margin-bottom: 2em;
}

.blog-wrapper article p {
  margin: 1em 0;
  font-size: 0.8em;
}

.blog-wrapper article a {
  color: #E87851;
}

.blog-wrapper article a.readmore {
  color: #707070;
  padding-bottom: 0;
  border-bottom: 1px solid transparent;
  transition: all 0.5s;
}

.blog-wrapper article a.readmore:hover, .blog-wrapper article a.readmore.active, a:hover .blog-wrapper article a.readmore, a.active .blog-wrapper article a.readmore {
  padding-bottom: 0.5em;
  border-bottom: 1px solid #707070;
}

.blog-sidebar {
  position: relative;
  z-index: 1;
  max-width: 360px;
  padding: 20px;
  display: none;
}

@media screen and (min-width: 1024px) {
  .blog-sidebar {
    display: block;
  }
}

.blog-sidebar .widget {
  margin: 0 0 30px;
  font-size: 16px;
}

.blog-sidebar .widget h3 {
  margin-bottom: 20px;
  font-weight: 400;
  color: #E87851;
}

.blog-sidebar .widget.widget_recent_entries ul {
  list-style-position: inside;
}

.blog-sidebar .widget.widget_recent_entries ul li {
  margin-bottom: 10px;
}

.blog-sidebar .widget.widget_recent_entries ul li a {
  color: #707070;
  padding-bottom: 0;
  border-bottom: 1px solid transparent;
  transition: all 0.5s;
}

.blog-sidebar .widget.widget_recent_entries ul li a:hover, .blog-sidebar .widget.widget_recent_entries ul li a.active, a:hover .blog-sidebar .widget.widget_recent_entries ul li a, a.active .blog-sidebar .widget.widget_recent_entries ul li a {
  padding-bottom: 0.3em;
  border-bottom: 1px solid #707070;
}

.single-post .post-content {
  background: #fff;
  padding: 30px;
  margin-bottom: 60px;
}

@media screen and (min-width: 1024px) {
  .single-post .post-content {
    padding: 60px;
    margin-right: 30px;
  }
}

.single-post .post-content p {
  margin: 0 0 1em;
  line-height: 1.4em;
}

.single-post .post-content img {
  margin: 50px 0;
}

.single-post .post-content img.aligncenter {
  display: block;
  margin: 50px auto;
}

.single-post .post-content.post-footer a {
  color: #707070;
  padding-bottom: 0;
  border-bottom: 1px solid transparent;
  transition: all 0.5s;
}

.single-post .post-content.post-footer a:hover, .single-post .post-content.post-footer a.active, a:hover .single-post .post-content.post-footer a, a.active .single-post .post-content.post-footer a {
  padding-bottom: 0.5em;
  border-bottom: 1px solid #707070;
}

.single-post .post-content.post-footer .column.ac {
  text-align: center;
}

.single-post .post-content.post-footer .column.al {
  text-align: left;
}

.single-post .post-content.post-footer .column.ar {
  text-align: right;
}

.graviola-pagination {
  background: #EBE9DB;
  position: relative;
  z-index: 1;
}

.graviola-pagination .graviola-pagination-wrapper {
  margin: 0 auto;
  padding: 30px;
  text-align: center;
}

.graviola-pagination a {
  color: #E87851;
  line-height: 3em;
}

body.single .graviola-pagination {
  color: #E87851;
}

body.single .graviola-pagination a {
  font-size: 90%;
  color: #E87851;
  padding-bottom: 0;
  border-bottom: 1px solid transparent;
  transition: all 0.5s;
}

body.single .graviola-pagination a:hover, body.single .graviola-pagination a.active, a:hover body.single .graviola-pagination a, a.active body.single .graviola-pagination a {
  padding-bottom: 0.3em;
  border-bottom: 1px solid #E87851;
}

.graviola-pagination ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
}

.graviola-pagination ul li {
  margin: 0;
  padding: 0;
  display: inline-block;
}

.graviola-pagination ul li a {
  margin: 4px;
  padding: 6px 12px;
  user-select: none;
  border-radius: 4px;
}

.graviola-pagination ul li a[disabled] {
  opacity: 0.5;
}

.graviola-pagination ul li a:hover:not(.is-current):not([disabled]) {
  background: #CED0AA;
}

.graviola-pagination ul li a.is-current {
  background: #E87851;
  color: #EBE9DB;
}

section.projetos-index {
  padding: 60px 30px;
  overflow: hidden;
}

@media screen and (min-width: 1024px) {
  section.projetos-index {
    padding: 120px;
  }
}

section.projetos-index .projetos-index-header {
  text-align: center;
  position: relative;
  z-index: 2;
}

section.projetos-index .projetos-index-frase {
  font-size: 2em;
  color: #E87851;
}

@media screen and (min-width: 1024px) {
  section.projetos-index .projetos-index-frase {
    width: 50%;
    margin: 0 auto;
  }
}

section.projetos-index .projetos-index-filter {
  margin: 2em;
  display: none;
}

@media screen and (min-width: 769px), print {
  section.projetos-index .projetos-index-filter {
    display: block;
  }
}

section.projetos-index .projetos-index-filter ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

section.projetos-index .projetos-index-filter li {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
}

section.projetos-index .projetos-index-filter li a {
  margin: 0 20px;
  color: #707070;
  padding-bottom: 0;
  border-bottom: 1px solid transparent;
  transition: all 0.5s;
}

section.projetos-index .projetos-index-filter li a:hover, section.projetos-index .projetos-index-filter li a.active, a:hover section.projetos-index .projetos-index-filter li a, a.active section.projetos-index .projetos-index-filter li a {
  padding-bottom: 0.5em;
  border-bottom: 1px solid #707070;
}

section.projetos-index .projetos-index-filter li + li::before {
  content: " • ";
  color: #E03E4A;
}

section.projetos-index .projetos-index-list {
  margin: 60px 0;
  position: relative;
  z-index: 2;
}

@media screen and (min-width: 1024px) {
  section.projetos-index .projetos-index-list {
    margin: 120px 0;
  }
}

section.projetos-index .projetos-index-list .projetos-index-list-wrapper {
  transition: all 0.5s;
}

section.projetos-index .projetos-index-list .projeto a {
  display: block;
  position: relative;
  overflow: hidden;
}

section.projetos-index .projetos-index-list .projeto a figure {
  line-height: 0;
}

section.projetos-index .projetos-index-list .projeto a .projeto-info {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 1.2em;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.3);
  transition: all 0.6s;
  border: 16px solid white;
  transform: scale(1.1);
}

section.projetos-index .projetos-index-list .projeto a:hover .projeto-info {
  opacity: 1;
  transform: scale(1);
}

section.cover {
  height: 100vh;
  position: relative;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  overflow: hidden;
  background: #000;
}

section.cover .cover-content {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 2.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

@media screen and (min-width: 769px), print {
  section.cover .cover-content {
    padding: 7vw;
  }
}

section.cover .cover-content.projetos {
  color: #fff;
  text-align: center;
}

section.cover .cover-content.projetos h2 {
  font-size: 2em;
  margin-bottom: 1em;
}

@media screen and (min-width: 769px), print {
  section.cover .cover-content.projetos h2 {
    width: 50%;
    margin: 0 auto 1em;
  }
}

section.cover .cover-content.projetos h1 {
  font-size: 1em;
}

section.cover .cover-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
}

section.cover .cover-video video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 100vh;
  min-width: 177.77vh;
  /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
}

section.cover .cover-slideshow {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 0.8;
}

section.cover .cover-slideshow-image {
  position: absolute;
  width: 100%;
  height: 100%;
  background: no-repeat 50% 50%;
  background-size: cover;
  opacity: 1;
  transform: scale(1.2);
}

section.cover.cover-minimal {
  height: 100px;
}

@media screen and (min-width: 1024px) {
  section.cover.cover-minimal {
    height: 30px;
  }
}

section.cover .cover-slideshow-3 .cover-slideshow-image {
  animation-name: kenburns;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 24s;
}

section.cover .cover-slideshow-3 .cover-slideshow-image:nth-child(1) {
  animation-name: kenburns-3-1;
  z-index: 2;
}

section.cover .cover-slideshow-3 .cover-slideshow-image:nth-child(2) {
  animation-name: kenburns-3-2;
  z-index: 1;
}

section.cover .cover-slideshow-3 .cover-slideshow-image:nth-child(3) {
  animation-name: kenburns-3-3;
  z-index: 0;
}

section.cover .cover-slideshow-4 .cover-slideshow-image {
  animation-name: kenburns;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 32s;
}

section.cover .cover-slideshow-4 .cover-slideshow-image:nth-child(1) {
  animation-name: kenburns-4-1;
  z-index: 3;
}

section.cover .cover-slideshow-4 .cover-slideshow-image:nth-child(2) {
  animation-name: kenburns-4-2;
  z-index: 2;
}

section.cover .cover-slideshow-4 .cover-slideshow-image:nth-child(3) {
  animation-name: kenburns-4-3;
  z-index: 1;
}

section.cover .cover-slideshow-4 .cover-slideshow-image:nth-child(4) {
  animation-name: kenburns-4-4;
  z-index: 0;
}

section.cover .cover-slideshow-5 .cover-slideshow-image {
  animation-name: kenburns;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 40s;
}

section.cover .cover-slideshow-5 .cover-slideshow-image:nth-child(1) {
  animation-name: kenburns-5-1;
  z-index: 4;
}

section.cover .cover-slideshow-5 .cover-slideshow-image:nth-child(2) {
  animation-name: kenburns-5-2;
  z-index: 3;
}

section.cover .cover-slideshow-5 .cover-slideshow-image:nth-child(3) {
  animation-name: kenburns-5-3;
  z-index: 2;
}

section.cover .cover-slideshow-5 .cover-slideshow-image:nth-child(4) {
  animation-name: kenburns-5-4;
  z-index: 1;
}

section.cover .cover-slideshow-5 .cover-slideshow-image:nth-child(5) {
  animation-name: kenburns-5-5;
  z-index: 0;
}

section.cover .cover-slideshow-6 .cover-slideshow-image {
  animation-name: kenburns;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 48s;
}

section.cover .cover-slideshow-6 .cover-slideshow-image:nth-child(1) {
  animation-name: kenburns-6-1;
  z-index: 5;
}

section.cover .cover-slideshow-6 .cover-slideshow-image:nth-child(2) {
  animation-name: kenburns-6-2;
  z-index: 4;
}

section.cover .cover-slideshow-6 .cover-slideshow-image:nth-child(3) {
  animation-name: kenburns-6-3;
  z-index: 3;
}

section.cover .cover-slideshow-6 .cover-slideshow-image:nth-child(4) {
  animation-name: kenburns-6-4;
  z-index: 2;
}

section.cover .cover-slideshow-6 .cover-slideshow-image:nth-child(5) {
  animation-name: kenburns-6-5;
  z-index: 1;
}

section.cover .cover-slideshow-6 .cover-slideshow-image:nth-child(6) {
  animation-name: kenburns-6-6;
  z-index: 0;
}

section.cover .cover-slideshow-7 .cover-slideshow-image {
  animation-name: kenburns;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 56s;
}

section.cover .cover-slideshow-7 .cover-slideshow-image:nth-child(1) {
  animation-name: kenburns-7-1;
  z-index: 6;
}

section.cover .cover-slideshow-7 .cover-slideshow-image:nth-child(2) {
  animation-name: kenburns-7-2;
  z-index: 5;
}

section.cover .cover-slideshow-7 .cover-slideshow-image:nth-child(3) {
  animation-name: kenburns-7-3;
  z-index: 4;
}

section.cover .cover-slideshow-7 .cover-slideshow-image:nth-child(4) {
  animation-name: kenburns-7-4;
  z-index: 3;
}

section.cover .cover-slideshow-7 .cover-slideshow-image:nth-child(5) {
  animation-name: kenburns-7-5;
  z-index: 2;
}

section.cover .cover-slideshow-7 .cover-slideshow-image:nth-child(6) {
  animation-name: kenburns-7-6;
  z-index: 1;
}

section.cover .cover-slideshow-7 .cover-slideshow-image:nth-child(7) {
  animation-name: kenburns-7-7;
  z-index: 0;
}

section.cover .cover-slideshow-8 .cover-slideshow-image {
  animation-name: kenburns;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 64s;
}

section.cover .cover-slideshow-8 .cover-slideshow-image:nth-child(1) {
  animation-name: kenburns-8-1;
  z-index: 7;
}

section.cover .cover-slideshow-8 .cover-slideshow-image:nth-child(2) {
  animation-name: kenburns-8-2;
  z-index: 6;
}

section.cover .cover-slideshow-8 .cover-slideshow-image:nth-child(3) {
  animation-name: kenburns-8-3;
  z-index: 5;
}

section.cover .cover-slideshow-8 .cover-slideshow-image:nth-child(4) {
  animation-name: kenburns-8-4;
  z-index: 4;
}

section.cover .cover-slideshow-8 .cover-slideshow-image:nth-child(5) {
  animation-name: kenburns-8-5;
  z-index: 3;
}

section.cover .cover-slideshow-8 .cover-slideshow-image:nth-child(6) {
  animation-name: kenburns-8-6;
  z-index: 2;
}

section.cover .cover-slideshow-8 .cover-slideshow-image:nth-child(7) {
  animation-name: kenburns-8-7;
  z-index: 1;
}

section.cover .cover-slideshow-8 .cover-slideshow-image:nth-child(8) {
  animation-name: kenburns-8-8;
  z-index: 0;
}

section.cover .cover-slideshow-9 .cover-slideshow-image {
  animation-name: kenburns;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 72s;
}

section.cover .cover-slideshow-9 .cover-slideshow-image:nth-child(1) {
  animation-name: kenburns-9-1;
  z-index: 8;
}

section.cover .cover-slideshow-9 .cover-slideshow-image:nth-child(2) {
  animation-name: kenburns-9-2;
  z-index: 7;
}

section.cover .cover-slideshow-9 .cover-slideshow-image:nth-child(3) {
  animation-name: kenburns-9-3;
  z-index: 6;
}

section.cover .cover-slideshow-9 .cover-slideshow-image:nth-child(4) {
  animation-name: kenburns-9-4;
  z-index: 5;
}

section.cover .cover-slideshow-9 .cover-slideshow-image:nth-child(5) {
  animation-name: kenburns-9-5;
  z-index: 4;
}

section.cover .cover-slideshow-9 .cover-slideshow-image:nth-child(6) {
  animation-name: kenburns-9-6;
  z-index: 3;
}

section.cover .cover-slideshow-9 .cover-slideshow-image:nth-child(7) {
  animation-name: kenburns-9-7;
  z-index: 2;
}

section.cover .cover-slideshow-9 .cover-slideshow-image:nth-child(8) {
  animation-name: kenburns-9-8;
  z-index: 1;
}

section.cover .cover-slideshow-9 .cover-slideshow-image:nth-child(9) {
  animation-name: kenburns-9-9;
  z-index: 0;
}

@keyframes kenburns-3-1 {
  0% {
    opacity: 1;
    transform: scale(1.2);
  }
  2.0833333333% {
    opacity: 1;
  }
  31.25% {
    opacity: 1;
  }
  35.4166666667% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
  97.9166666667% {
    opacity: 0;
    transform: scale(1.2117647059);
  }
  100% {
    opacity: 1;
  }
}

@keyframes kenburns-3-2 {
  31.25% {
    opacity: 1;
    transform: scale(1.2);
  }
  35.4166666667% {
    opacity: 1;
  }
  64.5833333333% {
    opacity: 1;
  }
  68.75% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}

@keyframes kenburns-3-3 {
  64.5833333333% {
    opacity: 1;
    transform: scale(1.2);
  }
  68.75% {
    opacity: 1;
  }
  97.9166666667% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

@keyframes kenburns-4-1 {
  0% {
    opacity: 1;
    transform: scale(1.2);
  }
  1.5625% {
    opacity: 1;
  }
  23.4375% {
    opacity: 1;
  }
  26.5625% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
  98.4375% {
    opacity: 0;
    transform: scale(1.2117647059);
  }
  100% {
    opacity: 1;
  }
}

@keyframes kenburns-4-2 {
  23.4375% {
    opacity: 1;
    transform: scale(1.2);
  }
  26.5625% {
    opacity: 1;
  }
  48.4375% {
    opacity: 1;
  }
  51.5625% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}

@keyframes kenburns-4-3 {
  48.4375% {
    opacity: 1;
    transform: scale(1.2);
  }
  51.5625% {
    opacity: 1;
  }
  73.4375% {
    opacity: 1;
  }
  76.5625% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}

@keyframes kenburns-4-4 {
  73.4375% {
    opacity: 1;
    transform: scale(1.2);
  }
  76.5625% {
    opacity: 1;
  }
  98.4375% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

@keyframes kenburns-5-1 {
  0% {
    opacity: 1;
    transform: scale(1.2);
  }
  1.25% {
    opacity: 1;
  }
  18.75% {
    opacity: 1;
  }
  21.25% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
  98.75% {
    opacity: 0;
    transform: scale(1.2117647059);
  }
  100% {
    opacity: 1;
  }
}

@keyframes kenburns-5-2 {
  18.75% {
    opacity: 1;
    transform: scale(1.2);
  }
  21.25% {
    opacity: 1;
  }
  38.75% {
    opacity: 1;
  }
  41.25% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}

@keyframes kenburns-5-3 {
  38.75% {
    opacity: 1;
    transform: scale(1.2);
  }
  41.25% {
    opacity: 1;
  }
  58.75% {
    opacity: 1;
  }
  61.25% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}

@keyframes kenburns-5-4 {
  58.75% {
    opacity: 1;
    transform: scale(1.2);
  }
  61.25% {
    opacity: 1;
  }
  78.75% {
    opacity: 1;
  }
  81.25% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}

@keyframes kenburns-5-5 {
  78.75% {
    opacity: 1;
    transform: scale(1.2);
  }
  81.25% {
    opacity: 1;
  }
  98.75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

@keyframes kenburns-6-1 {
  0% {
    opacity: 1;
    transform: scale(1.2);
  }
  1.0416666667% {
    opacity: 1;
  }
  15.625% {
    opacity: 1;
  }
  17.7083333333% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
  98.9583333333% {
    opacity: 0;
    transform: scale(1.2117647059);
  }
  100% {
    opacity: 1;
  }
}

@keyframes kenburns-6-2 {
  15.625% {
    opacity: 1;
    transform: scale(1.2);
  }
  17.7083333333% {
    opacity: 1;
  }
  32.2916666667% {
    opacity: 1;
  }
  34.375% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}

@keyframes kenburns-6-3 {
  32.2916666667% {
    opacity: 1;
    transform: scale(1.2);
  }
  34.375% {
    opacity: 1;
  }
  48.9583333333% {
    opacity: 1;
  }
  51.0416666667% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}

@keyframes kenburns-6-4 {
  48.9583333333% {
    opacity: 1;
    transform: scale(1.2);
  }
  51.0416666667% {
    opacity: 1;
  }
  65.625% {
    opacity: 1;
  }
  67.7083333333% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}

@keyframes kenburns-6-5 {
  65.625% {
    opacity: 1;
    transform: scale(1.2);
  }
  67.7083333333% {
    opacity: 1;
  }
  82.2916666667% {
    opacity: 1;
  }
  84.375% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}

@keyframes kenburns-6-6 {
  82.2916666667% {
    opacity: 1;
    transform: scale(1.2);
  }
  84.375% {
    opacity: 1;
  }
  98.9583333333% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

@keyframes kenburns-7-1 {
  0% {
    opacity: 1;
    transform: scale(1.2);
  }
  0.8928571429% {
    opacity: 1;
  }
  13.3928571429% {
    opacity: 1;
  }
  15.1785714286% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
  99.1071428571% {
    opacity: 0;
    transform: scale(1.2117647059);
  }
  100% {
    opacity: 1;
  }
}

@keyframes kenburns-7-2 {
  13.3928571429% {
    opacity: 1;
    transform: scale(1.2);
  }
  15.1785714286% {
    opacity: 1;
  }
  27.6785714286% {
    opacity: 1;
  }
  29.4642857143% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}

@keyframes kenburns-7-3 {
  27.6785714286% {
    opacity: 1;
    transform: scale(1.2);
  }
  29.4642857143% {
    opacity: 1;
  }
  41.9642857143% {
    opacity: 1;
  }
  43.75% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}

@keyframes kenburns-7-4 {
  41.9642857143% {
    opacity: 1;
    transform: scale(1.2);
  }
  43.75% {
    opacity: 1;
  }
  56.25% {
    opacity: 1;
  }
  58.0357142857% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}

@keyframes kenburns-7-5 {
  56.25% {
    opacity: 1;
    transform: scale(1.2);
  }
  58.0357142857% {
    opacity: 1;
  }
  70.5357142857% {
    opacity: 1;
  }
  72.3214285714% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}

@keyframes kenburns-7-6 {
  70.5357142857% {
    opacity: 1;
    transform: scale(1.2);
  }
  72.3214285714% {
    opacity: 1;
  }
  84.8214285714% {
    opacity: 1;
  }
  86.6071428571% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}

@keyframes kenburns-7-7 {
  84.8214285714% {
    opacity: 1;
    transform: scale(1.2);
  }
  86.6071428571% {
    opacity: 1;
  }
  99.1071428571% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

@keyframes kenburns-8-1 {
  0% {
    opacity: 1;
    transform: scale(1.2);
  }
  0.78125% {
    opacity: 1;
  }
  11.71875% {
    opacity: 1;
  }
  13.28125% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
  99.21875% {
    opacity: 0;
    transform: scale(1.2117647059);
  }
  100% {
    opacity: 1;
  }
}

@keyframes kenburns-8-2 {
  11.71875% {
    opacity: 1;
    transform: scale(1.2);
  }
  13.28125% {
    opacity: 1;
  }
  24.21875% {
    opacity: 1;
  }
  25.78125% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}

@keyframes kenburns-8-3 {
  24.21875% {
    opacity: 1;
    transform: scale(1.2);
  }
  25.78125% {
    opacity: 1;
  }
  36.71875% {
    opacity: 1;
  }
  38.28125% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}

@keyframes kenburns-8-4 {
  36.71875% {
    opacity: 1;
    transform: scale(1.2);
  }
  38.28125% {
    opacity: 1;
  }
  49.21875% {
    opacity: 1;
  }
  50.78125% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}

@keyframes kenburns-8-5 {
  49.21875% {
    opacity: 1;
    transform: scale(1.2);
  }
  50.78125% {
    opacity: 1;
  }
  61.71875% {
    opacity: 1;
  }
  63.28125% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}

@keyframes kenburns-8-6 {
  61.71875% {
    opacity: 1;
    transform: scale(1.2);
  }
  63.28125% {
    opacity: 1;
  }
  74.21875% {
    opacity: 1;
  }
  75.78125% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}

@keyframes kenburns-8-7 {
  74.21875% {
    opacity: 1;
    transform: scale(1.2);
  }
  75.78125% {
    opacity: 1;
  }
  86.71875% {
    opacity: 1;
  }
  88.28125% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}

@keyframes kenburns-8-8 {
  86.71875% {
    opacity: 1;
    transform: scale(1.2);
  }
  88.28125% {
    opacity: 1;
  }
  99.21875% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

@keyframes kenburns-9-1 {
  0% {
    opacity: 1;
    transform: scale(1.2);
  }
  0.6944444444% {
    opacity: 1;
  }
  10.4166666667% {
    opacity: 1;
  }
  11.8055555556% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
  99.3055555556% {
    opacity: 0;
    transform: scale(1.2117647059);
  }
  100% {
    opacity: 1;
  }
}

@keyframes kenburns-9-2 {
  10.4166666667% {
    opacity: 1;
    transform: scale(1.2);
  }
  11.8055555556% {
    opacity: 1;
  }
  21.5277777778% {
    opacity: 1;
  }
  22.9166666667% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}

@keyframes kenburns-9-3 {
  21.5277777778% {
    opacity: 1;
    transform: scale(1.2);
  }
  22.9166666667% {
    opacity: 1;
  }
  32.6388888889% {
    opacity: 1;
  }
  34.0277777778% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}

@keyframes kenburns-9-4 {
  32.6388888889% {
    opacity: 1;
    transform: scale(1.2);
  }
  34.0277777778% {
    opacity: 1;
  }
  43.75% {
    opacity: 1;
  }
  45.1388888889% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}

@keyframes kenburns-9-5 {
  43.75% {
    opacity: 1;
    transform: scale(1.2);
  }
  45.1388888889% {
    opacity: 1;
  }
  54.8611111111% {
    opacity: 1;
  }
  56.25% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}

@keyframes kenburns-9-6 {
  54.8611111111% {
    opacity: 1;
    transform: scale(1.2);
  }
  56.25% {
    opacity: 1;
  }
  65.9722222222% {
    opacity: 1;
  }
  67.3611111111% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}

@keyframes kenburns-9-7 {
  65.9722222222% {
    opacity: 1;
    transform: scale(1.2);
  }
  67.3611111111% {
    opacity: 1;
  }
  77.0833333333% {
    opacity: 1;
  }
  78.4722222222% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}

@keyframes kenburns-9-8 {
  77.0833333333% {
    opacity: 1;
    transform: scale(1.2);
  }
  78.4722222222% {
    opacity: 1;
  }
  88.1944444444% {
    opacity: 1;
  }
  89.5833333333% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}

@keyframes kenburns-9-9 {
  88.1944444444% {
    opacity: 1;
    transform: scale(1.2);
  }
  89.5833333333% {
    opacity: 1;
  }
  99.3055555556% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.cover-parallax {
  height: 100vh;
}

.cover-parallax .background {
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

@media screen and (min-width: 1024px) {
  .cover-parallax .background {
    background-attachment: fixed;
  }
}

.cover-parallax .background:after {
  content: '';
  position: absolute;
  background-color: #000000;
  opacity: .3;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.cover-parallax .background .frase {
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 2em;
  width: 90%;
  z-index: 2;
}

@media screen and (min-width: 769px), print {
  .cover-parallax .background .frase {
    width: 70%;
  }
}

section.galeria {
  padding: 2.4rem;
}

@media screen and (min-width: 1024px) {
  section.galeria {
    padding: 120px;
    max-width: 1200px;
    margin: 0 auto;
  }
}

.parallax-blocks {
  padding: 60px 0;
  overflow: hidden;
  color: #333;
}

.parallax-blocks .single-col {
  padding: 0 2.4rem;
}

@media screen and (min-width: 769px), print {
  .parallax-blocks .single-col {
    padding: 0 20vw;
  }
}

.parallax-blocks .single-col .poesia {
  position: relative;
  font-size: 1.2em;
  line-height: 1.2;
  margin: 10vw auto;
  max-width: 500px;
  z-index: 1;
}

.parallax-blocks .single-col .poesia.poesia-wide {
  max-width: 800px;
}

.parallax-blocks .single-col .frase {
  margin: 10vw auto;
  position: relative;
  text-align: center;
  font-size: 2em;
  font-weight: 300;
  z-index: 1;
  color: #E87851;
}

.parallax-blocks .double-col {
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
  z-index: 1;
}

.parallax-blocks .double-col.double-col-padding {
  margin: 7vw;
}

.parallax-blocks .double-col .double-col-padding-text {
  padding: 0 7vw;
}

@media screen and (min-width: 769px), print {
  .parallax-blocks .double-col .double-col-padding-text {
    padding: 7vw;
  }
}

.parallax-blocks .double-col .double-col-text {
  padding: 4vw 14vw;
}

.parallax-blocks .double-col .double-col-text h3 {
  font-size: 2em;
  margin-bottom: 1em;
  color: #E87851;
  font-weight: 400;
}

.parallax-blocks .double-col .double-col-text h4 {
  text-transform: uppercase;
  font-size: 0.75em;
  margin-bottom: 0.5em;
  color: #E87851;
}

.parallax-blocks .double-col .double-col-text p {
  font-size: 1.1em;
  margin-bottom: 1em;
}

@media screen and (min-width: 769px), print {
  .parallax-blocks .double-col .double-col-text {
    padding-left: 3.5vw;
    padding-right: 14vw;
  }
}

.parallax-blocks .double-col .double-col-figure {
  padding: 4vw 14vw;
}

.parallax-blocks .double-col .double-col-figure img {
  width: 100%;
  height: auto;
}

@media screen and (min-width: 769px), print {
  .parallax-blocks .double-col .double-col-figure {
    padding: 4vw;
  }
}

@media screen and (min-width: 769px), print {
  .parallax-blocks .double-col .double-col-txt-img {
    flex-direction: row-reverse;
  }
  .parallax-blocks .double-col .double-col-txt-img .double-col-text {
    padding-left: 14vw;
    padding-right: 3.5vw;
  }
}

@media screen and (min-width: 769px), print {
  .parallax-blocks .double-col .double-col-small .double-col-text p {
    font-size: 0.8em;
  }
}

.parallax-blocks .spacer {
  height: 20vw;
}

section.sanfona {
  padding: 7vw;
  background: #e87852;
  color: #fff;
  overflow: hidden;
}

@media screen and (min-width: 1024px) {
  section.sanfona {
    padding: 14vw;
  }
}

section.sanfona h3 {
  text-align: center;
  font-size: 2em;
  margin: 1em 0;
  position: relative;
  z-index: 2;
}

section.sanfona .sanfona-topicos-item {
  position: relative;
  z-index: 2;
  border: 1px solid #fff;
}

section.sanfona .sanfona-topicos-item:not(:first-child) {
  border-top: 0;
}

section.sanfona .sanfona-topicos-item .accordion-trigger {
  width: 100%;
  display: block;
  padding: 20px;
  color: #fff;
  font-weight: 400;
}

section.sanfona .sanfona-topicos-item .accordion-trigger .icon-more {
  margin-right: 10px;
}

@media screen and (min-width: 1024px) {
  section.sanfona .sanfona-topicos-item .accordion-trigger .icon-more {
    margin-right: 30px;
  }
}

section.sanfona .sanfona-topicos-item .accordion .accordion-wrapper {
  padding: 10px 30px 30px;
  font-size: 80%;
}

@media screen and (min-width: 1024px) {
  section.sanfona .sanfona-topicos-item .accordion .accordion-wrapper {
    padding: 10px 70px 30px;
  }
}

section.sanfona .sanfona-topicos-item .accordion .accordion-wrapper p {
  margin-bottom: 1em;
}

section.sanfona .sanfona-topicos-item .accordion .accordion-wrapper ul {
  margin: 1em;
}

section.sanfona .sanfona-topicos-item .accordion .accordion-wrapper .galeria {
  margin-top: 2em;
}

.accordion {
  overflow-y: hidden;
  max-height: 1000px;
  transition: all .5s;
}

.accordion.accordion-longtext {
  max-height: 2200px;
  transition: all 1s;
}

.accordion.closed {
  max-height: 0;
}

.icon-more {
  display: inline-block;
  width: 16px;
  height: 16px;
  position: relative;
}

.icon-more span {
  position: absolute;
  height: 2px;
  width: 100%;
  background: #fff;
  top: 50%;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

.icon-more.closed span:nth-child(1) {
  transform: rotate(90deg);
}

.icon-more.closed span:nth-child(2) {
  transform: rotate(180deg);
}

.slider-blog {
  position: relative;
  padding-bottom: 150px;
}

@media (min-width: 1000px) {
  .slider-blog {
    padding-bottom: calc(16.3043478261vw + -13.0434782609px);
  }
}

.slider-blog:before {
  content: '';
  position: absolute;
  bottom: 0;
  height: calc(50% + 200px);
  width: 100%;
  background-color: #676B66;
  z-index: -2;
}

.slider-blog h2 {
  font-size: 40px;
  color: #676B66;
  margin-bottom: 50px;
  margin-left: 10vw;
}

@media (min-width: 1000px) {
  .slider-blog h2 {
    font-size: 2.4em;
    margin-bottom: 100px;
  }
}

@media (min-width: 1000px) {
  .slider-blog .swiper-container {
    width: 80%;
    margin: 0 auto;
  }
}

.slider-blog .swiper-container .swiper-wrapper .swiper-slide {
  width: 60%;
}

.slider-blog .swiper-container .swiper-wrapper .swiper-slide .img {
  height: 200px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  margin-bottom: 20px;
}

@media (min-width: 1000px) {
  .slider-blog .swiper-container .swiper-wrapper .swiper-slide .img {
    height: 400px;
    width: 300px;
    margin-bottom: 40px;
  }
}

.slider-blog .swiper-container .swiper-wrapper .swiper-slide p {
  color: white;
  font-size: 16px;
  line-height: 1;
  font-weight: 400;
  z-index: 2;
}

@media (min-width: 1000px) {
  .slider-blog .swiper-container .swiper-wrapper .swiper-slide p {
    font-size: 1em;
  }
}

.slider-blog .swiper-container .swiper-button-prev,
.slider-blog .swiper-container .swiper-button-next {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 35px;
  height: 35px;
  outline: none;
}

@media (min-width: 1000px) {
  .slider-blog .swiper-container .swiper-button-prev,
  .slider-blog .swiper-container .swiper-button-next {
    width: 70px;
    height: 70px;
  }
}

.slider-blog .swiper-container .swiper-button-prev:after,
.slider-blog .swiper-container .swiper-button-next:after {
  content: '';
}

.slider-blog .swiper-container .swiper-button-prev {
  background-image: url("../assets/images/arrow-prev.svg");
  left: 5vw;
}

@media (min-width: 1000px) {
  .slider-blog .swiper-container .swiper-button-prev {
    left: -5vw;
  }
}

.slider-blog .swiper-container .swiper-button-next {
  background-image: url("../assets/images/arrow-next.svg");
  right: 5vw;
}

@media (min-width: 1000px) {
  .slider-blog .swiper-container .swiper-button-next {
    right: -5vw;
  }
}

.slider-blog .typeform {
  color: #E87851;
  font-size: 40px;
  text-align: center;
  margin: 150px auto 0;
  display: block;
}

@media (min-width: 1000px) {
  .slider-blog .typeform {
    font-size: 2.7em;
    margin: 300px auto 0;
  }
}

.slider-projects {
  position: relative;
}

.slider-projects:after {
  content: '';
  position: absolute;
  height: calc(50% + 13vw);
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: #EBE9DB;
  z-index: -1;
}

.slider-projects .swiper-container .swiper-wrapper .swiper-slide {
  width: 60vw;
  height: 60vw;
  margin: 0 2vw;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  cursor: pointer;
}

@media screen and (min-width: 769px), print {
  .slider-projects .swiper-container .swiper-wrapper .swiper-slide {
    height: 35vw;
  }
}

.slider-projects .swiper-container .swiper-wrapper .swiper-slide a {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-projects .swiper-container .swiper-wrapper .swiper-slide a:before {
  content: '';
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #000000;
  opacity: .2;
  position: absolute;
}

.slider-projects .swiper-container .swiper-wrapper .swiper-slide a .slider-projects-label {
  display: none;
  color: #fff;
  font-size: 1em;
  text-align: center;
  z-index: 2;
}

@media screen and (min-width: 1024px) {
  .slider-projects .swiper-container .swiper-wrapper .swiper-slide a .slider-projects-label {
    font-size: 1.2em;
  }
}

.slider-projects .swiper-container .swiper-wrapper .swiper-slide.swiper-slide-active .slider-projects-label {
  display: block;
}

.slider-projects .swiper-container .swiper-button-prev,
.slider-projects .swiper-container .swiper-button-next {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  margin-top: -25px;
  outline: none;
  transition: all 0.5s;
}

.slider-projects .swiper-container .swiper-button-prev:hover,
.slider-projects .swiper-container .swiper-button-next:hover {
  transform: scale(1.2);
}

@media screen and (min-width: 769px), print {
  .slider-projects .swiper-container .swiper-button-prev,
  .slider-projects .swiper-container .swiper-button-next {
    width: 100px;
    height: 100px;
    margin-top: -50px;
  }
}

.slider-projects .swiper-container .swiper-button-prev:after,
.slider-projects .swiper-container .swiper-button-next:after {
  content: '';
}

.slider-projects .swiper-container .swiper-button-prev {
  background-image: url("../assets/images/arrow-prev.svg");
  left: 2vw;
}

@media screen and (min-width: 769px), print {
  .slider-projects .swiper-container .swiper-button-prev {
    left: 5vw;
  }
}

.slider-projects .swiper-container .swiper-button-next {
  background-image: url("../assets/images/arrow-next.svg");
  right: 2vw;
}

@media screen and (min-width: 769px), print {
  .slider-projects .swiper-container .swiper-button-next {
    right: 5vw;
  }
}

.slider-projects .see-all {
  margin: 0 auto;
  padding: 8vw 0 16vw;
  display: table;
}

.slider-projects .see-all a {
  color: #707070;
  padding-bottom: 0;
  border-bottom: 1px solid transparent;
  transition: all 0.5s;
}

.slider-projects .see-all a:hover, .slider-projects .see-all a.active, a:hover .slider-projects .see-all a, a.active .slider-projects .see-all a {
  padding-bottom: 0.5em;
  border-bottom: 1px solid #707070;
}

.slider-projects-label {
  color: white;
  padding-bottom: 0;
  border-bottom: 1px solid transparent;
  transition: all 0.5s;
}

.slider-projects-label:hover, .slider-projects-label.active, a:hover .slider-projects-label, a.active .slider-projects-label {
  padding-bottom: 0.3em;
  border-bottom: 1px solid white;
}

.parallax-svg {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
}

.parallax-svg.svg-256 {
  width: 128px;
  margin-top: -64px;
  margin-left: -64px;
}

@media screen and (min-width: 1024px) {
  .parallax-svg.svg-256 {
    width: 14vw;
    margin-top: -7vw;
    margin-left: -7vw;
  }
}

.parallax-svg.svg-512 {
  width: 256px;
  margin-top: -128px;
  margin-left: -128px;
}

@media screen and (min-width: 1024px) {
  .parallax-svg.svg-512 {
    width: 28vw;
    margin-top: -14vw;
    margin-left: -14vw;
  }
}

.parallax-svg.svg-1024 {
  width: 512px;
  margin-top: -256px;
  margin-left: -256px;
}

@media screen and (min-width: 1024px) {
  .parallax-svg.svg-1024 {
    width: 56vw;
    margin-top: -28vw;
    margin-left: -28vw;
  }
}

.footer {
  overflow: hidden;
  background-color: #676B66;
  padding: 40vw 10vw 10vw;
  min-height: 100vh;
  color: #ffffff;
}

@media screen and (min-width: 769px), print {
  .footer {
    padding: 0 10vw 10vw;
  }
}

@media (min-width: 700px) {
  .footer {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}

.footer .marca {
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  width: 260px;
  display: none;
}

@media screen and (min-width: 769px), print {
  .footer .marca {
    display: block;
    width: 40%;
    margin-bottom: 0;
  }
}

.footer .infos {
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.footer .infos p {
  margin: 0 0 0.5em;
}

.footer .infos a {
  color: #ffffff;
  font-weight: 400;
  margin-bottom: 10px;
}

.footer .infos .social {
  margin-top: 2em;
}

.footer .infos .social a {
  transition: all 0.5s;
  display: inline-block;
}

.footer .infos .social a:hover {
  transform: scale(1.2);
}

.footer .infos svg {
  fill: #ffffff;
  height: 35px;
}

.footer .infos svg:first-of-type {
  margin-right: 10px;
}
