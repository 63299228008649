.header {
	
	z-index: 20;
	position: fixed;
	top: 0;
	left: 0;
	
	.header-logo {

		position: absolute;
		top: 50px;
		left: 50px;
		width: 128px;//responsive(50, 65, 1000, 1920);
		height: 128px;
		margin-left: -64px;
		margin-top: -64px;
		transition: all .5s;
		z-index: 21;
		transform: scale(0.75);

		@include desktop {
			top: 75px;
			left: 90px;
			width: 128px;
			transform: scale(1);
		}

		img {
			position: absolute;
			top: 0;
			left: 0;
			height: 128px;
			width: 128px;
			transition: all .5s;
		}

		body.top-is-cover:not(.show-menu):not(.single-graviola-projetos) & {
			transform: translate3d(0,-300px,0);
		}

		#logo-mask, #logo-clip {
			transition: transform .5s, opacity .5s;
			transform-origin: 50% 50%;
		}

		body.single-graviola-projetos.top-is-cover &,
		body.show-menu &,
		body.top-is-white &,
		body.top-is-footer & {
			#logo-mask, #logo-clip {
				transform: scale(1.5);
			}
			#logo-clip {
				opacity: 0;
			} 
		}
	}

	.header-menu {

		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		transform: translate3d(0,-100vh,0);
		background-color: rgba(103, 107, 102, 0.95);
		transition: all .5s;
		cursor: pointer;
		z-index: 20;

		body.show-menu & {
			transform: translate3d(0,0,0);
		}

		.header-menu-wrapper {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			overflow: hidden;
		}

		.header-menu-links {
			
			list-style: none;
			margin: 0;
			padding: 0;
			
			li {
				height: 4em;
				text-align: center;

				a {
					@include animated-underline($pure-white, 0.2em);
					font-size: 2em;
					font-weight: 400;
				}
			}

		}

		.header-menu-social {
			
			list-style: none;
			margin: 20px 0 0;
			padding: 0;

			li {

				display: inline-block;
				margin: 0 10px;

				a {
					transition: all 0.5s;
					display: block;
					&:hover {
						transform: scale(1.2);
					}
					svg {
						fill: $pure-white;
					}
				}
			}
		}
		
	}

}

.icon-burger {

	position: fixed;
	top: 40px;
	right: 30px;
    width: 40px;
    height: 24px;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
	user-select: none;
	z-index: 21;

	@include desktop {
		top: 64px;
		right: 60px;
	}
  
    span {
        margin: 0;
        padding: 0;
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: $dark-gray;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .25s ease-in-out;
    }

    span:nth-child(1) {
        top: 0px;
    }

    span:nth-child(2),
    span:nth-child(3) {
        top: 12px;
    }

    span:nth-child(4) {
        top: 24px;
    }

	body.top-is-footer &,
	body.top-is-cover &,
	body.top-is-white &,
	body.show-menu & {
		span {
			background: $light-gray;
		}
	}

    body.show-menu & {

		span:nth-child(1) {
			top: 12px;
			width: 0%;
			left: 50%;
		}

		span:nth-child(2) {
			transform: rotate(45deg);
		}

		span:nth-child(3) {
			transform: rotate(-45deg);
		}

		span:nth-child(4) {
			top: 12px;
			width: 0%;
			left: 50%;
		}

	}

}